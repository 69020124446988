/**
 *
 * 该组件提供一个 lazyCall 方法，用于延迟执行可能会导致浏览器 loaded 的方法。
 * 这些方法包括：GA统计、GOOGLE广告、外部JSONP接口调用、动态 iframe 和 img 等。
 * 原理是保证传入的方法在 document.readyState == 'complete'（body load）后再执行；
 * 并且如果达到 INTERACTIVE_TIMEOUT 时间后也需要执行。
 *
 * 调用方法：
 * lazyCall(function() {
 *    // ...
 * });
 *
 * Created by zhengguo.chen on 2017/11/30.
 */

(function (window) {
  'use strict';
  const INTERACTIVE_TIMEOUT = 3000;
  /**
   * LazyCall class
   * @return {function(this:LazyCall)}
   * @constructor
   */
  var LazyCall = function() {
    this.INTERACTIVE_TIMEOUT = INTERACTIVE_TIMEOUT;  // interactive 状态后的的延迟执行时间
    this._ready = false; // ready 状态
    this._listeners = []; // 待执行的方法队列
    this._init(); // 初始化
    return this.addListener.bind(this); // 初始化后返回一个新的方法，bind this 保证调用时候作用域正确
  };

  /**
   * 监听 document readyState，如果 complete 立即执行，否则 INTERACTIVE_TIMEOUT 秒后执行。
   * @return
   */
  LazyCall.prototype._init = function() {
    var _this = this;
    var ready = function() {
      ready = function() {}; // 保证该方法仅能被调用一次
      _this._ready = true;
      _this.callListeners();
    }
    document.addEventListener('readystatechange', function() {
      if(document.readyState === 'complete') {
        ready();
      }
      if(document.readyState === 'interactive') {
        setTimeout(ready, _this.INTERACTIVE_TIMEOUT);
      }
    });
  };

  /**
   * 执行当前队列里的所有方法
   */
  LazyCall.prototype.callListeners = function() {
    var lazyFn;
    while(typeof (lazyFn = this._listeners.shift()) === 'function') {
      setTimeout(lazyFn); // complete 后不能立即执行，防止转圈
    }
  };

  /**
   * 实例化后返回的方法
   * @param fn
   * @return
   */
  LazyCall.prototype.addListener = function(fn) {
    this._listeners.push(fn);
    this._ready && this.callListeners();
  };

  // 实例化后，暴露到 $ 或 window
  if (window.requestIdleCallback) {
    window.dmLazyCall = function(f) {
      return requestIdleCallback(f, { timeout: INTERACTIVE_TIMEOUT });
    };
  } else {
    window.dmLazyCall = new LazyCall();
  }
})(window);
