
import { luxmoonAdjust } from './applinks';

function popup () {
  const $2x = window.devicePixelRatio >= 2 ? '@2x' : '';
  const countryCode = 'US';
  const iPad = countryCode === 'US';
  const siteName = '北美省钱快报';
  const appDownloadTemplate = `
<div class="dm-sg-modal dm-sg-modal-download hide" style="display: none;">
  <div class="dm-sg-modal-content">
    <i class="dm-sg-modal-close dm-icon dm-icon-close" data-action="close"></i>
    <div class="dm-sg-modal-download-bg">
      <img src="/assets/image/app_detail_luxmoon${$2x}.png" alt="Dealmoon.com" />
      <div class="dm-sg-modal-download-app-info">
        <div class="app-logo">
          <img src="/assets/image/sites/${countryCode}/app_icon${$2x}.png" alt="Dealmoon.com">
          <div class="app-intro">
            <p class="app-name">${siteName} App</p>
            <p>找好物 享好价</p>
          </div>
        </div>
        <div class="platform-logo">
          <div class="logo-icons">
            <i class="dm-icon dm-icon-pingguo"></i>
            ${iPad ? '<i class="dm-icon dm-icon-tablet-ipad"></i>' : ''}
            <i class="dm-icon dm-icon-android1"></i>
          </div>
          <div class="platform-info">
            <p>iPhone / ${iPad ? 'iPad / ' : ''}Android </p>
            <p>扫一扫二维码下载App</p>
          </div>
        </div>
      </div>
    </div>
    <div class="dm-sg-modal-download-img">
    
    </div>
  </div>
</div>`;
  if (appDownloadTemplate) {
    const appDownloadHTML = appDownloadTemplate;
    const $modal = $(appDownloadHTML);
    const codeOptions = {
      text: luxmoonAdjust,
      correctLevel: 0,
      width: 108,
      height: 108,
      foreground: "#333"
    };
    $modal.find(".dm-sg-modal-download-img").qrcode(codeOptions);
    $modal.showModal({ darkMask: true });
  }
}

function onLuxmoonClick (selector) {
  let content;
  if (!selector) {
    content = document;
  } else if (selector instanceof HTMLElement) {
    content = selector;
  } else if (selector[0] instanceof HTMLElement) {
    content = selector[0];
  } else {
    content = document.querySelector('.edit-content');
  }
  if (content) {
    content.addEventListener('click', function (e) {
      if (e && e.target) {
        var a = e.target.closest('a[href*="/luxmoon"], a.luxmoon-link');
        if (a) {
          if (a.href) {
            a.setAttribute('data-href', a.href);
            a.removeAttribute('href');
            a.style.cursor = 'pointer';
            a.classList.add('luxmoon-link');
          }
          e.preventDefault();
          popup();
        }
      }
    }, true);
  }
}

export default onLuxmoonClick;
