/*
 * @Author: Gang Jiang
 * @Date: 2021-12-15 11:40:51
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2021-12-15 11:41:21
 */
// https://www.cnblogs.com/litao229/archive/2012/06/20/2555968.html
export default (root, el) => {
  if (root.compareDocumentPosition)
    return root === el || !!(root.compareDocumentPosition(el) & 16)
    
  if (root.contains && el.nodeType === 1) {
    return root.contains(el) && root !== el
  }

  while ((el = el.parentNode)) if (el === root) return true

  return false
}
