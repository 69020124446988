/*
 * @Author: Gang Jiang
 * @Date: 2020-09-15 11:16:33
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2024-02-27 17:29:06
 * @Description:
 */
import { dmEventDelegate } from 'dm-dom'
import { url } from 'dm-utils-ts'

function replaceUrlParameters(originalUrl, params) {
  if (!originalUrl) return ''
  if (!params) return originalUrl

  let urlReplaced = originalUrl
  let hashindex = originalUrl.indexOf('#')
  if (hashindex > -1) {
    urlReplaced = originalUrl.substr(0, hashindex)
  }
  hashindex = urlReplaced.indexOf('?')
  let baseUrl = urlReplaced.substr(0, hashindex)

  let baseParams = {}
  if (hashindex > -1) {
    let tmpParameters = urlReplaced.substr(hashindex + 1).split('&')
    tmpParameters.forEach((tmpParameter, index) => {
      if (tmpParameter.indexOf('=') > -1) {
        let arr = tmpParameter.split('=')
        baseParams[arr[0]] = arr[1]
      }
    })
  }
  let urlParamArr = []
  for (let key in baseParams) {
    urlParamArr.push(`${key}=${baseParams[key]}`)
  }
  for (let key in params) {
    if (baseParams[key]) {
      urlParamArr[key] = `${key}=${encodeURIComponent(params[key])}`
    } else {
      urlParamArr.push(`${key}=${encodeURIComponent(params[key])}`)
    }
  }
  let urlParams = urlParamArr.join('&')

  return `${baseUrl}?${urlParams}`
}

export const getUpdateTrackRipLink = (dom, trkripHref) => {
  let rip = dom.getAttribute('trkrip')

  // search-sp-item: PC搜索 -> 抢好货
  // sp-subject-border: PC搜索 -> 折扣 -> 单品区域
  if (
    /"pvp"/.test(rip) &&
    window.index &&
    window.index.newSearch &&
    (dom.parentNode.className.indexOf('sp-subject-border') != -1 ||
      dom.closest('.search-sp-item'))
  ) {
    try {
      const ripArr = rip.split('|')
      const ripData = JSON.parse(ripArr[3])

      ripData.pvp.isFirstClick = index.newSearch.isFirstClick

      ripArr[3] = JSON.stringify(ripData)
      rip = ripArr.join('|')

      console.log(rip)
    } catch (e) {
      console.log(e)
    }
  }

  // wap搜索 单品
  if (
    /"pvp"/.test(rip) &&
    window.index &&
    window.index.pageType == 'searchindex' &&
    dom.parentNode.className.indexOf('sp-subject-wrap') != -1
  ) {
    try {
      const ripArr = rip.split('|')
      const ripData = JSON.parse(ripArr[3])

      let isFirstClick = false
      let searchStore = localStorage.getItem('dm-search-result-clicked')

      if (searchStore) {
        searchStore = JSON.parse(searchStore)
        isFirstClick = searchStore.clickTimes === 1
      }
      ripData.pvp.isFirstClick = isFirstClick

      ripArr[3] = JSON.stringify(ripData)
      rip = ripArr.join('|')
    } catch (e) {
      console.log(e)
    }
  }

  const trkrip = btoa(rip)

  return trkrip.length ? url.replaceUrlParam(trkripHref, 'rip', trkrip) : trkripHref;
}

function ripTrack(execContainer, burry) {
  if (window.ripTrackInitialized) return;
  window.ripTrackInitialized = true;
  execContainer = execContainer ? execContainer[0] : null
  // ajax请求需要添加修改ripTrack的情况
  if (execContainer) {
    try {
      let buyExecBurry = JSON.parse(execContainer.dataset['buyExecBurry']);

      buyExecBurry = { ...buyExecBurry, ...burry };

      [].forEach.call(execContainer.querySelectorAll('a[href*="exec/j"]'), ele => {
        let link = ele.getAttribute('href')

        if (url.isDeamoonUrl(ele.hostname)) {
          ele.setAttribute('href', replaceUrlParameters(link, buyExecBurry))
        }
      })
    } catch (e) {
      console.log(e);
    }

    return
  }

  const rip = url.getQuery('rip') // 取URL中的rip参数

  if (
    rip.length &&
    document.referrer &&
    url.isDeamoonUrl(url.parse(document.referrer).host)
  ) {
    // document.referrer避免带有rip信息的URL分享出去后打开带来的错误埋点
    // 只处理含有exec和trkrip的链接
    [].forEach.call(document.body.querySelectorAll('a[href*="exec/j"]'), function (ele) {
      let link = ele.getAttribute('href')

      if (url.isDeamoonUrl(ele.hostname)) {
        // 判断是否是dealmoon链接
        const ripArr = helper.base64decode(rip).split('|')

        // pageType在模版文件定义到全局变量window中
        pageType && (link = url.replaceUrlParam(link, 'click_page', pageType))

        // 单品详情页单独处理
        if (
          'product_detail' === pageType || // PC
          (interfacedata.platform === 'WAP' &&
            /\/product\//.test(location.pathname)) // WAP
        ) {
          link = url.replaceUrlParam(link, 'click_page', 'dpzq_xq')
        }

        link = url.replaceUrlParam(
          link,
          'platform',
          interfacedata.platform.toUpperCase()
        )

        link = url.replaceUrlParam(link, 'rip', ripArr[0])
        link = url.replaceUrlParam(link, 'lang', interfacedata.lan)
        ripArr[1] && (link = url.replaceUrlParam(link, 'rip_value', ripArr[1]))
        ripArr[2] &&
          (link = url.replaceUrlParam(link, 'rip_position', ripArr[2]))

        if (ripArr[3]) {
          try {
            const param = JSON.parse(ripArr[3])

            if (!param.pvp) {
              ripArr[3] &&
                (link = url.replaceUrlParam(
                  link,
                  'dm_data',
                  encodeURIComponent(decodeURIComponent(ripArr[3]))
                ))
            } else {
              if (param.pvp.fo) {
                link = url.replaceUrlParam(
                  link,
                  'rip',
                  ripArr[0] + '_' + param.pvp.fo
                )
              }
              if (param.pvp.exec_rip) {
                link = url.replaceUrlParam(link, 'rip', param.pvp.exec_rip)
              }
              if (param.pvp.exec_rip_value) {
                link = url.replaceUrlParam(link, 'rip_value', param.pvp.exec_rip_value)
              }
            }
          } catch (e) {
            throw new Error(e)
          }
        }

        // 折扣详情/猜你喜欢 点击进详情页的购买按钮exec的rip_value取上一个页面的ID
        if (ripArr[0] === 'deal_detail_recommend') {
          const referer = document.referrer
          const prevPageDealId = referer
            .substring(referer.lastIndexOf('/') + 1, referer.lastIndexOf('?'))
            .replace(/\D/g, '')

          if (prevPageDealId) {
            link = url.replaceUrlParam(link, 'rip_value', prevPageDealId)
          }
        }

        ele.setAttribute('href', link)
      }
    })
  }

  dmEventDelegate(document.body, 'click', 'a[trkrip]', function (e) {
    if (url.isDeamoonUrl(this.hostname)) {
      const _t = this.closest('a[trkrip]');
      let trkripHref = _t.getAttribute('href')

      if (!/exec\/j/.test(trkripHref) && !_t.hasAttribute('gallery-link')) {
        const target = _t.getAttribute('target');
        trkripHref = getUpdateTrackRipLink(_t, trkripHref);

        if (target == '_blank') {
          try {
            // 打开新窗口
            var tempwindow = window.open('about:blank'); // 先打开页面

            if (tempwindow) {
              tempwindow.location = trkripHref; // 后更改页面地址
            } else {
              location.href = trkripHref
            }
          } catch (e) {
            console.log(e);
            location.href = trkripHref;
          }
        } else {
          // WAP当前页面URL更新
          location.href = trkripHref
        }
        e.preventDefault();
        e.returnValue = false
      }
    }
  })
}

// 直接执行trkrip
ripTrack()

window.ripTrack = ripTrack
export default ripTrack
