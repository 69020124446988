/*
 * 事件委托方法
 * @Author: Gang Jiang
 * @Date: 2021-11-15 10:49:42
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2021-12-15 15:39:13
 * @param parent {string|HTMLDocument|Element|Window}: 代理节点选择器，如'.parent', window, document, document.body
 * @param eventName {string}：事件名称，如'click'
 * @param sub {string}: 子节点选择器，如'.sub-item'
 * @param fn {function}: 事件回调方法
 * @eg import { dmEventDelegate } from 'dm-dom'
 *     var ctn = document.querySelector('#menu_container');
 *     dmEventDelegate(ctn, 'click','.sub-item', function(event){ console.log('clicked ...'); });
 * @see https://www.cnblogs.com/xtreme/p/10910677.html
 */

import domContains from "./contains"

export default function (parent, eventName, sub, fn) {
  let parentNodes = parent;

  if (typeof parent === 'object' && (parent instanceof Window || parent instanceof HTMLDocument || parent instanceof Element)) {
    parentNodes = [parent];
  } else if (typeof parent == 'string') {
    parentNodes = document.querySelectorAll(parent)
  } else {
    console.error('父节点类型不支持，请确认！');

    return false;
  }
  
  if (parentNodes.length) {
    parentNodes.forEach(function(ele) {
      ele.addEventListener(
        eventName,
        function(event) {
          const target = event.target
          const list = parent.querySelectorAll(sub)

          if (list && Array.from(list).includes(target)) {
            if (typeof fn == "function") fn.apply(target, arguments)
          } else if (
            target.closest(sub) &&
            domContains(parent, target.closest(sub))
          ) {
            if (typeof fn == "function")
              fn.apply(target.closest(sub), arguments)
          }
        },
        false
      )
    })
  }
}
