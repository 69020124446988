/*
 * @Author: Gang Jiang 
 * @Date: 2023-10-18 10:46:51 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2023-11-29 17:05:27
 * 依赖: jquery.qrcode.js, dmscroll, makeLazy, jquery
 */

require('./right-float.less');

class DmRightFloat {
  constructor(options) {
    this.options = {
      wH: 450,
      size: 80,
      ...options,
    }
    this.$float = $('.dm-right-float');
    this.$floatNav = $('.dm-right-float .float-nav');
    this.$qrcode = $('.dm-right-float--qrcode .j-qrcode');

    return () => {
      this.bindEvents();
      // 生成二维码
      this.qrcode();

      // 滚动一屏显示回到顶部
      this.checkShow = $.makeLazy(this.todayShow.bind(this), 100, 10);
      $.dmscroll(this.checkShow);
      $(window).on("resize", this.checkShow);
    }
  }

  qrcode() {
    if (this.$qrcode.length && !this.$qrcode.hasClass('qrcode-loaded')) {
      const adgroup = window.pageInfo['adjustLabel'] ? window.pageInfo['adjustLabel'].replace(/[_\d]*/g, '') : location.pathname.substring(1).replace(/\//g, '-');
      const codeOptions = {
        text: `${window.appDownloadAdjustLinkUrl}?campaign=${common.getAdjustCampaign()}&adgroup=${adgroup}${window.pageInfo['adjustLabel'] ? '&label=' + window.pageInfo['adjustLabel'] : ''}`,
        correctLevel: 0,
        width: this.options.size,
        height: this.options.size,
        foreground: "#da3764"
      };

      if (this.options.foreground) {
        codeOptions.foreground = this.options.foreground;
      } else {
        delete codeOptions.foreground;
      }

      this.$qrcode.addClass('qrcode-loaded').qrcode(codeOptions);
    }
  }

  todayShow() {
    // 滚动一屏
    if (this.$floatNav.length) {
      if ($(window).scrollTop() > this.options.wH) {
        this.$float.addClass('show-top');
      }
    } else {
      this.$float.toggleClass('show-top', $(window).scrollTop() > this.options.wH);
    }
  }

  bindEvents() {
    $(document).on("click", ".gotop", () => {
      // 回到顶部点击事件
      $("html, body").animate({ scrollTop: 0 }, 500);
    }).on('click', '#today-hot-jubao', () => {
      let dialogHtml = $("#jubao_html").html();
      if (window.common.jubao_dialog == null) {
        window.common.jubao_dialog = new Dialog(
          { type: "text", value: dialogHtml },
          {
            title: "举报信息",
            opacity: 1,
            beforeClose: function () {
              window.common.jubao_dialog.hide();
              return false;
            }
          }
        );
      }
      window.common.jubao_dialog.show();

      var token = '';
      try {
        token = /(^|[\s;]+)usertoken=([^;]+)/.test(document.cookie)
          ? decodeURIComponent(RegExp.$2)
          : '';
      } catch (error) { }

      // 添加右侧栏举报按钮埋点信息
      window.ga4 && window.ga4('send', {
        hitType: 'event',
        eventCategory: 'dm-floatingbar-click',
        eventAction: 'click-dm-home-right-floatingbar-report',
        dimension3: window.customPvParam && window.customPvParam.dimension3 ? window.customPvParam.dimension3 : '',
        dimension17: 'dm',
        dimension19: window.customPvParam && window.customPvParam.dimension19 ? window.customPvParam.dimension19 : '',
        dimension22: window.dmLang || 'cn',
        eventLabel: 'yh:' + (token && /(\d+)\|/.test(token) ? RegExp.$1 : '0') + '|pf:pc|pgn:home'
      });
    });
  }
}

window.dmRightFloat = new DmRightFloat();