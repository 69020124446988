/*
 * @Author: liming
 * @Date: 2019-06-10 15:09:31
 * @Last Modified by: liming
 * @Last Modified time: 2019-09-11 09:56:15
 * 站点引导跳转: https://rally1.rallydev.com/#/58549189294d/detail/userstory/308482624600
 */

import "./index.less";

import AU from "./AU.jpeg";
import CA from "./CA.png";
import FR from "./FR.png";
import GB from "./GB.png";
import US from "./US.png";
import DE from "./DE.png";

class SiteJump {
  constructor(option, params) {
    //各种设置
    this.setting = option;
    this.$jumpModal = $(".site-jump-modal");
    this.needJumpCodeMap = {
      FR: {
        name: "法国",
        logo: FR,
        site: "https://www.dealmoon.fr"
      },
      GB: {
        name: "英国",
        logo: GB,
        site: "https://www.dealmoon.co.uk"
      },
      CA: {
        name: "加拿大",
        logo: CA,
        site: "https://www.dealmoon.ca"
      },
      AU: {
        name: "澳洲",
        logo: AU,
        site: "https://www.dealmoon.com.au"
      },
      DE: {
        name: "德国",
        logo: DE,
        site: "https://www.dazhe.de"
      }
    };

    this._judgeJump();
  }

  // 判断是否弹出
  _judgeJump() {
    let cookieChooseJumpSite = $.cookie("choose-jump-site");

    // 美国站环境，选择的非美国站点，并且用户所在地区在跳转站点内
    if (
      this.setting.countryCode === "US" &&
      !cookieChooseJumpSite &&
      Object.keys(this.needJumpCodeMap).indexOf(this.setting.visitCountry) != -1
    ) {
      this._showModal();
    } else if (
      cookieChooseJumpSite === "US" &&
      Object.keys(this.needJumpCodeMap).indexOf(this.setting.visitCountry) != -1
    ) {
      this._dynamicAppendSite();
    }
  }

  // 关闭弹窗
  _closeModal() {
    $.cookie("choose-jump-site", 'US', { expires: 365, path: "/" });
    this.$jumpModal.removeClass("show").hide();
  }

  // 动态添加分站到导航
  _dynamicAppendSite() {
    let cc = this.needJumpCodeMap[this.setting.visitCountry];
    if (!cc) return;
    $(
      `<li class="tab-choose-site" data-dmt="" data-dmt-g-a="click-dm-[pgn]-header-tab-choose-site"> <a href=${
        cc.site
      } class="ga_event_statistics_nav_list" target="_blank" gatrk="choose-site_">${
        cc.name
      }站</a></li>`
    ).insertAfter($(".nav_top li:eq(0)"));
  }

  // 初始化事件
  _initEvent() {
    this.$jumpModal.on("click", ".dm-icon-close", () => {
      this._closeModal();
    });

    this.$jumpModal.on("click", '[data-role="jump-selector"] a', e => {
      let $target = $(e.currentTarget).closest("a");
      let data = $target.data();
      $.cookie("choose-jump-site", data.site, { expires: 365, path: "/" });
      this._closeModal();
      if (data.site == "US") {
        this._dynamicAppendSite();
        e.stopPropagation();
        return false;
      }
    });
  }

  // 显示弹窗
  _showModal() {
    if (!this.$jumpModal.length) {
      let cc = this.needJumpCodeMap[this.setting.visitCountry];
      let siteName = `${cc.name}省钱快报`
      if (cc.name === '德国') {
        siteName = '德国打折网'
      }
      let html = `<div class="site-jump-modal">
          <div class="site-jump-modal-dialog">
              <div class="title"><span class="close-modal" data-role="closemodal">
                <i class="dm-icon dm-icon-close"></i>
                </span>
              </div>
              <ul class="selector" data-role="jump-selector">
                  <li>
                    <p>了解更多${cc.name}折扣，推荐访问</p>
                    <a data-site=${this.setting.visitCountry} href=${
        cc.site
      } target="_blank"><img src=${cc.logo} alt=${cc.name}/>${siteName}
                    </a>
                  </li>
                  <li>
                    <p>继续访问</p>
                    <a data-site="US" href=""><img src=${US} alt="北美省钱快报"/>
                      北美省钱快报
                    </a>
                  </li>
              </ul>
          </div>
          <div class="site-jump-cover"></div>
      </div>`;

      $(html).appendTo("body");
      this.$jumpModal = $(".site-jump-modal");
      this.$jumpModal.show();
      this.$jumpModal[0].offsetWidth; // force reflow
      this.$jumpModal.addClass("show");
      this._initEvent();
    }
  }
}

$(() => {
  // let visitCountry = "GB";
  if(window.dmLang === 'cn' && window.pageType === 'home') {
    new SiteJump({
      visitCountry: window.visitCountry,  // 用户访问实际所在国家
      countryCode: window.countryCode
    });
  }
});
