import { url, cookie } from "dm-utils-ts";

var HOST_PATTERN = /^https?\:\/\/([^\/]+)/;
var COOKIE_EXPIRES = 12 * 3600 * 1000;
var DOMAIN_PATTERN = /(\.dealmoon\.com\.au|\.dealmoon\.co\.uk|\.dealmoon\.ca|\.dealmoon\.fr|\.dazhe\.de|\.dealmoon\.com|\.dealmoon\.cn|\.[ais]t\w+.dealmoon.net)$/;

var SESSION_KEY = '__dealmoon_session__';
var SESSION_SYNC_KEY = '__sync_session';
var SESSION_COOOKIE = 'dm_ssid';
var SESSION_EVENT_TIMEOUT = 500;

function getUuid () {
  if (typeof crypto === 'object') {
    if (typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID();
    }
    if (typeof crypto.getRandomValues === 'function' && typeof Uint8Array === 'function') {
      const callback = (c) => {
        const num = Number(c);
        return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(16);
      };
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, callback);
    }
  }
  let timestamp = new Date().getTime();
  let perforNow = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let random = Math.random() * 16;
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0;
      timestamp = Math.floor(timestamp / 16);
    } else {
      random = (perforNow + random) % 16 | 0;
      perforNow = Math.floor(perforNow / 16);
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
  });
};

function newSession () {
  let session = ([ getUuid(),  Date.now() ]).join('|');
  sessionStorage.setItem(SESSION_KEY, session);
  console.log('开始新的 session', session);
  return session;
}

export default function () {
  var referrer = document.referrer;
  var cookieDomain = DOMAIN_PATTERN.test(location.host) ? RegExp.$1 : "";

  if (cookieDomain) {
    var cookieOptions = { domain: cookieDomain, path: "/", secure: true };
    var landing_page = location.href.split("#")[0];

    var expires = new Date();
    expires.setTime(expires.getTime() + COOKIE_EXPIRES);
    cookieOptions.expires = expires;

    // 1. 处理utm相关cookie，因为详情页会直接由CDN返回，utm相关cookie在后端设置不了，前台补充一次
    if (/(\?|&)utm[_\w]*=[^&]+/.test(location.search)) {
      let s = url.getQuery("s"),
        utm_source = url.getQuery("utm_source"),
        utm_medium = url.getQuery("utm_medium"),
        utm_campaign = url.getQuery("utm_campaign"),
        utm_content = url.getQuery("utm_content"),
        utm_term = url.getQuery("utm_term");
      try {
        utm_source && cookie.setCookie("utm_source", utm_source, cookieOptions);
        utm_medium && cookie.setCookie("utm_medium", utm_medium, cookieOptions);
        utm_campaign && cookie.setCookie("utm_campaign", utm_campaign, cookieOptions);
        utm_content && cookie.setCookie("utm_content", utm_content, cookieOptions);
        utm_term && cookie.setCookie("utm_term", utm_term, cookieOptions);
        s && cookie.setCookie("sid", s, cookieOptions);
      } catch (error) {
        console.error(error);
      }
    }

    // 2. 处理 referrer_url/landing_page 逻辑
    var referrerHost = '';
    var cookieReferrerUrl;
    if (referrer) {
      referrerHost = HOST_PATTERN.test(referrer) ? RegExp.$1 : "";
      if (!DOMAIN_PATTERN.test(referrerHost)) {
        // 外站进入，且当前域名正常
        cookieReferrerUrl = referrer;
        try {
          cookie.setCookie("referrer_url", cookieReferrerUrl, cookieOptions);
          cookie.setCookie("landing_page", landing_page, cookieOptions);
          setTimeout(function () {
            // Cookie 记录 Landing 的 Session
            if (sessionStorage[SESSION_KEY]) {
              cookie.setCookie('landing_ssid', sessionStorage[SESSION_KEY], cookieOptions);
            }
          }, SESSION_EVENT_TIMEOUT + 20);
        } catch (error) {
          console.error(error);
        }
      }
    } else if (/(\?|&)utm[_\w]*=[^&]+/.test(location.search)) {
      // 如果没有 referrer，有 utm 参数，可能是来自微博
      // 修改：如果有 utm，没有 referrer，则 referrer_url 为空，landing_page 有值
      try {
        cookie.setCookie("landing_page", landing_page, cookieOptions);
        cookie.deleteCookie("referrer_url", cookieDomain);
        setTimeout(function () {
          // Cookie 记录 Landing 的 Session
          if (sessionStorage[SESSION_KEY]) {
            cookie.setCookie('landing_ssid', sessionStorage[SESSION_KEY], cookieOptions);
          }
        }, SESSION_EVENT_TIMEOUT + 20);
      } catch (error) {
        console.error(error);
      }
    }

    // 3. 添加 landing 新用户标识 cookie
    // 判断是否是新用户（如果 cookie 中没有 udid，则认为是新用户）。
    // 因为 cookie 可能在 PHP 被写入 udid，所以在 libs/ClickTrackerLib.php:setCookieUUID 中写入 udid 时，
    // 同时写入了 new_user 的 cookie，以此作为新用户标识
    var new_user, udid;
    try {
      new_user = cookie.readCookie('new_user');
      udid = cookie.readCookie('udid');

      // 如果 new_user 为 0，则表明 PHP 中写入了 UDID，且是新用户
      // 否则，表示 PHP 中没有写入，此时如果 UDID 没有，且之前没有写入过 new_user = 1 的 cookie，也算新用户
      if (new_user === '0' || (!udid && new_user != 1)) {
        cookie.setCookie("new_user", '1', cookieOptions);
        if (cookieReferrerUrl) {
          cookie.setCookie("new_user_referrer_url", cookieReferrerUrl, cookieOptions);
        } else {
          cookie.deleteCookie("new_user_referrer_url", cookieDomain);
        }
        var landing_utm_campaign = landing_page && /(\?|&)utm_campaign=([^&]+)/.test(landing_page) ? RegExp.$2 : '';
        if (landing_utm_campaign) {
          cookie.setCookie("new_user_utm_campaign", landing_utm_campaign, cookieOptions);
        } else {
          cookie.deleteCookie("new_user_utm_campaign", cookieDomain);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (window.sessionStorage && window.localStorage) {
    let newSessionTimeout;
    window.addEventListener('storage', function (e) {
      if (!e.newValue) {
        return;
      }
      if (e.key === SESSION_SYNC_KEY) {
        // 当别的页面接收到需要同步 Session 的事件，则取出自己的 Session
        // console.log('收到 session 同步请求');
        let session = sessionStorage.getItem(SESSION_KEY);
        if (!session) {
          // 如果没有，则创建一个新的 Session
          session = newSession();
          document.cookie = SESSION_COOOKIE + '=' + session;
        }
        // 通过设置 localStorage 把 Session ID 广播出去
        localStorage.setItem(SESSION_KEY, '');
        // 先设置为空值，再设置为具体的值，因为相同的值不会触发 storage 事件
        localStorage.setItem(SESSION_KEY, session);
        // console.log('发送 session 同步广播', session);
      } else if (e.key === SESSION_KEY && !sessionStorage.getItem(SESSION_KEY)) {
        // 如果接收到 Session ID 的广播，且自己没有 Session ID，则使用接收到的 Session ID
        // console.log('收到 Session 同步广播', e.newValue);
        sessionStorage.setItem(SESSION_KEY, e.newValue);
        clearTimeout(newSessionTimeout);
      }
    });

    // 首先判断 sessionStorage 中是否有 Session
    let session = sessionStorage.getItem(SESSION_KEY);
    if (!session) {
      // console.log('当前标签页面没有 Session，发送同步请求');
      // 如果没有，则通过设置 localStorage 来发起一个 storage 事件，请求 Session 广播。
      localStorage.setItem(SESSION_SYNC_KEY, Date.now());
      newSessionTimeout = setTimeout(function () {
        session = sessionStorage.getItem(SESSION_KEY);
        if (!session) {
          // console.log('500ms 后仍然没有同步到 Session');
          // 如果 500ms 后没有接收到 Session，则说明没有其他窗口或 tab，自己创建一个新的 Session
          session = newSession();
        }
        document.cookie = SESSION_COOOKIE + '=' + session;
      }, SESSION_EVENT_TIMEOUT);
    } else {
      document.cookie = SESSION_COOOKIE + '=' + session;
    }
  }
}
