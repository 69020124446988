/**
 * Created by JetBrains PhpStorm
 * User: liming
 * Date: 2016/11/21
 */

// import Compressor from 'compressorjs';
import Compressor from '../dm-image-compress';

(function ($, win, undef) {

  /**
   * 文件ajax异步上传
   * 使用方法:
   * $(selector).ajaxfileupload({
        action: url,
        params: {
            path: '/images/ul/',
            dest: 'date_auto',
            file: 'img1',
            state : 'singleDeal'
        },
        onStart: function(url ,cb) {
        },
        onComplete: function(data) {
        }
      });
   * @param options
   * @returns {*}
   */

  $.fn.ajaxfileupload = function (options) {
    var settings = {
      params: {},
      action: '',
      showLoading: false,
      limitWidth:  1440,
      limitHeight:  1440,
      onUploading: function () {},
      onStart: function () {},
      onComplete: function (response) {},
      onCancel: function () {},
      validate_extensions: true,
      valid_extensions: ['gif', 'png', 'jpg', 'jpeg'],
      submit_button: null
    };

    var uploadingFile = false;

    if (options) {
      $.extend(settings, options);
    }

    return this.each(function () {
      var $element = $(this), file = null, canvasDataUrl = null;

      // Skip elements that are already setup. May replace this
      //  with uninit() later, to allow updating that settings
      if ($element.data('ajaxUploader-setup') === true) return;

      $element.on('change', function () {
        // since a new image was selected, reset the marker
        uploadingFile = false;
        // only update the file from here if we haven't assigned a submit button
        if (settings.submit_button == null) {
          uploadFile();
        }
      });

      function actualUpload() {
        wrapElement($element, function ($form) {
          $form.on('submit', function (e) {
            e.stopPropagation();
            settings.showLoading && settings.onUploading();
          }).submit();
        });
      }

      function uploadFile() {
        if ($element.val() == '') return settings.onCancel.apply($element, [settings.params]);

        // make sure extension is valid
        var ext = $element.val().split('.').pop().toLowerCase();
        if (true === settings.validate_extensions && $.inArray(ext, settings.valid_extensions) == -1) {
          // Pass back to the user
          settings.onComplete.apply($element, [{
            status: false,
            message: 'The select file type is invalid. File must be ' + settings.valid_extensions.join(', ') + '.'
          }, settings.params]);
        } else {
          uploadingFile = true;

          if (!settings.onStart) {
            actualUpload();
          } else {
            new Compressor($element[0].files[0], {
              maxSize: 400,
              maxWidth: 1440,
              success: res => {
                // 返回blob数据
                var a = new FileReader();
                a.readAsDataURL(res);
                a.onload = function (e) {
                  canvasDataUrl = e.target.result;
                  settings.onStart.apply($element, [canvasDataUrl, function (go) {
                    if (go) {
                      actualUpload();
                    } else {
                      uploadingFile = false;
                    }
                  }]);
                };
              }
            });
          }
        }
      }

      if (settings.submit_button == null) {
        // do nothing
      } else {
        settings.submit_button.on('click', function (e) {
          // Prevent non-AJAXy submit
          e.preventDefault();
          // only attempt to upload file if we're not uploading
          if (!uploadingFile) {
            uploadFile();
          }
        });
      }

      // Mark this element as setup
      $element.data('ajaxUploader-setup', true);

      /*
       // Internal handler that tries to parse the response
       //  and clean up after ourselves.
       */
      var handleResponse = function (loadedFrame, element) {
        var response, responseStr = $(loadedFrame).contents().text();
        try {
          response = JSON.parse(responseStr);
        } catch (e) {
          response = responseStr;
        }
        // Tear-down the wrapper form
        element.siblings().remove();
        element.unwrap();

        uploadingFile = false;
        // Pass back to the user
        settings.onComplete.apply(element, [response, settings.params]);
      };

      /*
       // Wraps element in a <form> tag, and inserts hidden inputs for each
       //  key:value pair in settings.params so they can be sent along with
       //  the upload. Then, creates an iframe that the whole thing is
       //  uploaded through.
       */
      var wrapElement = function (element, cb) {
        // Create an iframe to submit through, using a semi-unique ID
        var frame_id = 'ajaxUploader-iframe-' + Math.round(new Date().getTime() / 1000);
        $('body').after('<iframe width="0" height="0" style="display:none;" name="' + frame_id + '" id="' + frame_id + '"/>');

        $('#' + frame_id).on('load', function () {
          handleResponse(this, element);
        });
        // Wrap it in a form
        element.wrap(function () {
          return '<form action="' + settings.action + '" method="POST" enctype="multipart/form-data" target="' + frame_id + '" />';
        }).after(function () {
          var key, html = '';
          for (key in settings.params) {
            var paramVal = settings.params[key];
            if (typeof paramVal === 'function') {
              paramVal = paramVal();
            }
            html += '<input type="hidden" name="' + key + '" value="' + paramVal + '" />';
          }
          return html;
        });
        
        const $form = element.parent();

        if (canvasDataUrl) {  
          var newFile = document.createElement('input');
          newFile.setAttribute('type', 'hidden');
          newFile.setAttribute('name', 'cuttedImg');
          newFile.value = canvasDataUrl;
          element.after(newFile);
          canvasDataUrl = null;
          element.remove(); // 删除.post-img, 避免上传文件过大
        }
        
        cb($form);
      };
    });
  };

})(jQuery, window);