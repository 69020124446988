import './index.less';

let showTimerDelay = 30000; //30 secs
let countdownTimer = null;
let countdownTimerDelay = 5000; //5 secs

const setCookie = () => {
  // $.cookie('newslettersubscribe', '1', {
  //   expires: 30,
  //   domain: document.domain.slice(document.domain.indexOf('.')),
  //   path: '/',
  // });
  //expires in 30 days
  localStorage.setItem('newslettersubscribe',new Date().getTime())
}


const getSubscribed = (userId,email) => {
  console.log(topbar.user);
  return new Promise(reslove => {
    $.post(
      common.subscribed, {
        userId,
        email
      },
      res => {
        reslove(res);
      },
      'json'
    );
  });
}

const getSubscribeThreshold = () => {
  return new Promise(reslove => {
    $.post(
      common.subscribeThreshold, {},
      res => {
        reslove(res);
      },
      'json'
    )
  });
}

const doSubscribe = (userId = '', email) => {
  return new Promise(reslove => {
    getSubscribed(userId, email).then(res => {
      if (res.success) {
        if (res.data) {//has subscribed
          renderSuccess(true);
          reslove('ok');
        }else{//not subscribe
          $.post(
            common.subscribe, {
              userId,
              email
            },
            res => {
              renderSuccess(false);
              reslove('ok');
            },
            'json'
          ).catch(e => {
            reslove({error:1});
          });
        }
      }else{
        // alert('订阅异常，请重试!');
        reslove({error:1});
      }
    });
  })
}

const countDown = (isShowCountDown = true) => {
  const $ele = $('#dm-newsletter-subscribe .dm-newsletter-subscribe-timer');
  let count = 5;
  if (isShowCountDown) {
    $ele.html(`本窗口将在${count}秒内自动关闭`);    
  }
  countdownTimer = setInterval(() => {
    if (count == 1) {
      toggleVis(false);
      clearInterval(countdownTimer);
    } else {
      count--;
      if (isShowCountDown) {
        $ele.html(`本窗口将在${count}秒内自动关闭`);
      }
    }
  }, 1000);
}

const renderFirst = (isLogged = false) => {
  const $wraperInner = $('#dm-newsletter-subscribe .dm-newsletter-subscribe-inner');
  let html = '';
  if (!isLogged) {
    html = `<div class="dm-newsletter-subscribe-slogan">一键订阅，新品首发、bug价一手抢！</div>
  <div class="dm-newsletter-subscribe-input">
    <input placeholder="输入Email" type="text" id="newsletter-subscribe-email">
    <button type="button" data-dmt class="newsletter-do-subscribe event_statics_action" data-dmt data-dmt-g-c="dm_notification_click" data-dmt-g-a="click-dm-notification-subscribe">订阅</button>
  </div><div class="dm-newsletter-subscribe-error"><i class="dm-icon dm-icon-warning"></i>请输入邮箱</div>`
  } else {
    html = `<div class="dm-newsletter-subscribe-slogan">免费订阅邮件，我们一起<span>找好物，享好价！</span></div>
    <div class="dm-newsletter-subscribe-useold-btn event_statics_action" data-dmt data-dmt-g-c="dm_notification_click" data-dmt-g-a="click-dm-notification-subscribe">
      <span>继续使用“</span><button type="button">${(topbar && topbar.user && topbar.user.userEmail)?topbar.user.userEmail:''}</button><span>”订阅</span>
    </div>
    <div class="dm-newsletter-subscribe-change"><span>更换其他邮箱</span></div>`;
  }
  $wraperInner.html(html);
}

const renderSuccess = (hasSubscribed = false) => {
  let html = '';
  if (!hasSubscribed) {
    html = `<div class="dm-newsletter-subscribe-thx">感谢订阅</div>
 <div class="dm-newsletter-subscribe-feedback">您将收到一封确认邮件，请打开并确认您的Email地址，完成订阅</div>`;
  } else {
    html = `<div class="dm-newsletter-subscribe-thx">该邮箱已订阅，感谢您的关注！</div>
 <div class="dm-newsletter-subscribe-timer"></div>`;
  }
  $('#dm-newsletter-subscribe .dm-newsletter-subscribe-inner')
    .addClass('dm-newsletter-subscribe-done')
    .html(html);
  if (hasSubscribed) {
    countDown(true);
  }else{
    countDown(false);
  }
}

const scroll = {
  on: () => {
    const $wraper = $('#dm-newsletter-subscribe');
    const $scrollfix = $('#scroll-fix'); //last google ad on the right side
    const wraperOriginTop = $wraper.css('top').replace(/px/, '') * 1;
    const pageType = $('body').attr('class').split(' ')[0];
    console.log(pageType);
    const topFix = {
      'page-type-homeindex': 48,
      'page-type-rankindex': 95,
      'page-type-rankproduct': 95,
      'page-type-ranksoaring-list':55,
      'page-type-rankpost':55,
      'page-type-rankguide':55,
      'page-type-rankhot-comments':55,
      'page-type-productindex':86,
      'page-type-producttop-cate':44,
      'page-type-producthot-all':44,
      'page-type-producttrend': 44,
      'page-type-productevent':44,
      'page-type-producthot-cate':44,
      'page-type-producthot-aggregate':44,
      'page-type-productcategory':86
    };
    //UK site
    if($('body#UK').length){
      topFix['page-type-rankindex'] = 115;
    }
    const $cateQuery = $('.cate-query');
    const $top_cate = $('#top_cate');
    const $rank_cate = $('#rank_cate');
    const $spfixwrap = $('.sp-fix-wrap');
    const cateQueryHeight = $cateQuery.height();
    const $productTitle = $('.product-title');
    $(window).on('scroll.newslettersubscribe', function() {
      // if (['home', 'deal', 'category'].includes(common.shortId)) {
      //   if ($scrollfix.css('position') == 'static') {
      //     $wraper.show();
      //   } else {
      //     $wraper.hide();
      //   }
      // }
      const isHomePageFixed = pageType == 'page-type-homeindex' && ($('.nav_bot').hasClass('nav_fixed'));
      const isRankPageFixed = [
        'page-type-rankindex',
        'page-type-rankproduct',
        'page-type-ranksoaring-list',
        'page-type-rankpost',
        'page-type-rankguide',
        'page-type-rankhot-comments'
      ].includes(pageType) && ($('body').hasClass('topfixed') || $('body').hasClass('fixed'));//uk site popular page category bar fixed class is fixed not topfixed
      const isProductPageFixed = [
        'page-type-productindex',
        'page-type-producttop-cate',
        'page-type-producthot-all',
        'page-type-productevent',
        'page-type-producthot-cate',
        'page-type-producttrend',
        'page-type-producthot-aggregate',
        'page-type-productcategory'
      ].includes(pageType) && ($cateQuery.hasClass('fixed') || $rank_cate.hasClass('fixed') || $spfixwrap.hasClass('sp-fix-show') || $top_cate.hasClass('fixed') || $productTitle.hasClass('fixed'));
      // console.log(isRankPageFixed,isProductPageFixed,isHomePageFixed);
      if (isHomePageFixed || isProductPageFixed || isRankPageFixed) {
        $wraper.css('top',(topFix[pageType] + 6));
      }else{
        $wraper.css('top',wraperOriginTop);
      }
      // if (common.shortId == 'product') {
      //   // if (($cateQuery && $cateQuery.hasClass('fixed')) || ($top_cate && $top_cate.hasClass('fixed')) || ($rank_cate && $rank_cate.hasClass('fixed'))) {
      //   //   $wraper.hide();
      //   // } else {
      //   //   $wraper.show();
      //   // }
      //   if ($cateQuery && $cateQuery.hasClass('fixed')) {
      //     $wraper.css('top',cateQueryHeight);
      //   }else{
      //     $wraper.css('top','null');
      //   }
      // }
      // if ($(window).scrollTop() > 0) {
      //   $wraper.stop().animate({
      //     top: topFix[common.shortId]
      //   },250);
      // } else {
      //   $wraper.stop().animate({
      //     top: wraperOriginTop
      //   },250);
      // }
    });
  },
  off: () => {
    $(window).off('scroll.newslettersubscribe');
  }
}

const toggleVis = (vis = true, isLogged = false) => {
  const $wraper = $('#dm-newsletter-subscribe');
  if (vis) {
    renderFirst(isLogged);
    $wraper.show();
  } else {
    $wraper.hide();
    scroll.off();
    setCookie();
  }
}

const initPopup = async () => {
  if (!(window.Storage && window.localStorage && window.localStorage instanceof Storage)) {
    return;
  }
  const cookieNewslettersubscribe = $.cookie('newslettersubscribe');
  const localStorageNewslettersubscribe = localStorage.getItem('newslettersubscribe');
  // const nowDateTime = new Date().getTime()*1;
  // const expiresDateTime = localStorageNewslettersubscribe?localStorageNewslettersubscribe*1:0;
  //fix cookie lost issure;
  if (cookieNewslettersubscribe && !localStorageNewslettersubscribe) {
    setCookie();
  }
  //delete localstorage after 30 days
  // if (expiresDateTime && expiresDateTime - nowDateTime == 0) {
  //   localStorage.removeItem('newslettersubscribe');
  // }
  const hasClosed = $.cookie('newslettersubscribe') || localStorage.getItem('newslettersubscribe');
  if (!$.cookie('usertoken')) {
    try {
      if (!hasClosed) { //never closed
        const res = await getSubscribeThreshold();
        if (!res.data) { //over 5 times subscribed in a day
          // setTimeout(() => {
            toggleVis(true, false);
            scroll.on();
          // }, showTimerDelay);
        }
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    $(window).on('userlogged', async function() {
      try {
        const userId = topbar && topbar.user && topbar.user.userId?topbar.user.userId:'';
        const email = topbar && topbar.user && topbar.user.userEmail?topbar.user.userEmail:'';
        if (!hasClosed) { //never closed
          const res = await getSubscribed(userId,email);
          if (!res.data) { //no subscribed
            // setTimeout(() => {
              toggleVis(true, false);
              scroll.on();
            // }, showTimerDelay);
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
    //has invalid usertoken
    $(window).on('usernotlogin', async function() {
      try {
        if (!hasClosed) { //never closed
          const res = await getSubscribeThreshold();
          if (!res.data) { //over 5 times subscribed in a day
            // setTimeout(() => {
              toggleVis(true, false);
              scroll.on();
            // }, showTimerDelay);
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  }
}
const event = function() {
  const $wraper = $('#dm-newsletter-subscribe');
  const $wraperInner = $wraper.find('.dm-newsletter-subscribe-inner');
  $('#dm-newsletter-subscribe')
    .on('click', '.dm-newsletter-subscribe-close', function() { //close popover
      toggleVis(false);
    })
    .on('click', '.dm-newsletter-subscribe-change span', function() { //change other email
      $wraperInner
        .html(`<div class="dm-newsletter-subscribe-slogan">免费订阅邮件，我们一起<span>找好物，享好价！</span></div>
  <div class="dm-newsletter-subscribe-input">
    <input placeholder="输入Email" type="text" id="newsletter-subscribe-email">
    <button class="newsletter-do-subscribe event_statics_action" data-dmt data-dmt-g-c="dm_notification_click" data-dmt-g-a="click-dm-notification-subscribe">订阅</button>
  </div>
  <div class="dm-newsletter-subscribe-error"><i class="dm-icon dm-icon-warning"></i>请输入邮箱</div>
  <div class="dm-newsletter-subscribe-useold"><span>继续使用“</span><span class="dm-newsletter-subscribe-useold-account">${(topbar && topbar.user && topbar.user.userEmail)?topbar.user.userEmail:''}</span><span>“订阅</span></div>`)
    })
    .on('click', '.newsletter-do-subscribe', function() { //make subscribe
      const $this = $(this);
      const email = $('#newsletter-subscribe-email').val();
      if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)) {
        // alert('请输入正确的邮箱地址!');
        $('.dm-newsletter-subscribe-error').show();
        return;
      }else{
        $('.dm-newsletter-subscribe-error').hide();
      }
      $this.attr('disabled', true);
      doSubscribe(topbar.user.userId, email).then(res => {
        if (res.error) {
          alert('订阅异常，请重试!');
        }
        $this.attr('disabled', false);
      }).finally(res => {
        $this.attr('disabled', false);
      })
    })
    .on('click', '.dm-newsletter-subscribe-useold', function() { //use old(go back to [continue status])
      renderFirst(true);
    })
    .on('click', '.dm-newsletter-subscribe-useold-btn', function() { //continue
      const $this = $(this);
      $this.attr('disabled', true);
      doSubscribe(topbar.user.userId, topbar.user.userEmail).then(res => {
        if (res.error) {
          alert('订阅异常，请重试!');
        }
      }).finally(res => {
        $this.attr('disabled', false);
      })
    });
  $('#mailgo').on('click', function() {
    setCookie();
  });
}

$(function() {
  if ($('#dm-newsletter-subscribe').length) {
    initPopup();
    event();
  }
});