import "./header.less";
import "./topbar.less";

var topbar = {
  lang: null,
  login_url: null,
  curPage: null,
  isCHT: false,
  host: "",
  searchRequestHandle: null,
  recommendWord: null,
  recommend_url: null,
  popular_store_url: "/www/store/get-popular-stores",
  placeHolder: null
};

function readCookie(name) {
  //获取指定名称的cookie的值
  if ($ && $.cookie) {
    return $.cookie(name) || "";
  } else {
    var arrstr = document.cookie.split("; ");
    for (var i = 0; i < arrstr.length; i++) {
      var temp = arrstr[i].split("=");
      if (temp[0] == name) return unescape(temp[1]);
    }
    return "";
  }
}

function htmlEncodeByRegExp(str) {
  var s = "";
  if (!str || str.length == 0) return "";
  s = str.replace(/</g, "&lt;");
  s = s.replace(/>/g, "&gt;");
  return s;
}
function htmlEncodeByRegExpAfter(str) {
  var s = "";
  if (!str || str.length == 0) return "";
  s = str.replace(/&/g, "&amp;");
  s = s.replace(/ /g, "&nbsp;");
  s = s.replace(/\'/g, "&#39;");
  s = s.replace(/\"/g, "&quot;");
  return s;
}

// topbar最顶部
topbar.top = {
  //根据ip判断语境提示弹框
  selectLang: function() {
    // $(".login_after_box")
    //   .on("mouseenter", function() {
    //     $("#user_item")
    //       .stop(false, true)
    //       .fadeIn(100)
    //       .end();
    //   })
    //   .on("mouseleave", function() {
    //     $("#user_item")
    //       .stop(false, true)
    //       .fadeOut(100)
    //       .end();
    //   });
  },
  readSearchHistory() {
    let historyData = $.cookie('searchHistoryData');
    let history = [];
    if (historyData) {
      // 下面判断，2021年7月后可删除
      if (~historyData.indexOf('||')) {
        // 矫正老数据，直接更新cookie内容
        historyData = historyData
          .split('||')
          .map(value => encodeURIComponent(value))
          .join(',');
        $.cookie('searchHistoryData', historyData, {
          expires: 30,
          path: '/',
          domain: document.domain.slice(document.domain.indexOf('.')),
          secure: true,
        });
      }
      history = historyData.split(',');
    }
    return history.map(value => decodeURIComponent(value));
  },
  joinHistory(keyword) {
    const history = this.readSearchHistory();
    const idx = history.indexOf(keyword)
    if (idx > -1) history.splice(idx, 1)
    history.unshift(keyword);
    if (history.length > 10) history.pop();
    $.cookie('searchHistoryData', history.map(value => encodeURIComponent(value)).join(','), {
      expires: 30,
      path: '/',
      domain: document.domain.slice(document.domain.indexOf('.')),
      secure: true,
    });
  },
  //顶部搜索
  search: {
    getSearchCategory: () => {
      const typemaps = {
        '1': 'deal', 
        '4': 'instruction',
        '5': 'ugc_content',
      };

      return typemaps[location.search.replace(/^\S*type=(\d)\S*$/, '$1')];
    },

    style: function() {
      var defaultVal = $("#t_keyword").attr("defaultvalue");
      // fix #18013
      var isFocused = $("#t_keyword").is(":focus");
      if ($("#t_keyword").val() == "" && !isFocused) {
        $("#t_keyword").val(defaultVal);
      }

      $("#t_keyword")
        .on("focus", function() {
          $(this).css("color", "#000");
          $(this)
            .parent(".sf_text")
            .addClass("key_focus");
          var textVal = $(this).val();
          if (textVal == defaultVal) {
            $(this).val("");
          }
          $(this)
            .next(".search_data")
            .show();
        })
        .on("blur", function() {
          $(this)
            .parent(".sf_text")
            .removeClass("key_focus");
          var textVal = $(this).val();
          if (textVal == "" || textVal == defaultVal) {
            $(this).val(defaultVal);
            $(this).css("color", "#9a9a9a");
          } else {
            $(this).css("color", "#000");
          }
        });

      // fix #18013
      if (isFocused) {
        $("#t_keyword").trigger("focus");
      }
    },

    suggest: function() {
      if (disableSearchRecommends) {
        topbar.top.search._onSearchData(() => {}, ".t_keyword");
        topbar.top.search._onSearchData(() => {}, ".search_gs_input");
        return;
      }

      var _this = this;

      function sendDataBack(kWord, inputObj) {
        const historyData = topbar.top.readSearchHistory();
        const words = historyData.join(',').replace(/\s/g, ' ');
        function handleSearchRequest() {
          topbar.searchRequest = $.ajax({
            type: "POST",
            url: topbar.suggest_url,
            async: true,
          data: { keyword: kWord, words },
            dataType: "json",
            success: function(data) {
              //将返回的数据传递给实现搜索输入框的输入提示js类
              inputObj.disData(data);
            }
          });
          topbar.searchRequestHandle = null;
        }

        //当触发新的查询时，取消已发送的查询
        if (topbar.searchRequestHandle != null) {
          clearTimeout(topbar.searchRequestHandle);
        }
        topbar.searchRequestHandle = setTimeout(handleSearchRequest, 200);
      }

      topbar.top.search._onSearchData(sendDataBack, ".t_keyword");
      topbar.top.search._onSearchData(sendDataBack, ".search_gs_input");

      $(".search_fra form").on("submit", function(e) {
        var keyword = $("#t_keyword")
          .val()
          .trim();
        if (keyword == $("#t_keyword").attr("defaultvalue") || keyword == "") {
          return false;
        }
        // 搜索埋点
        if (!topbar._loged) {
          _this.statistics(keyword);
          var $form = $(this);
          // 防止埋点请求被浏览器cancel
          setTimeout(function() {
            topbar._loged = true;
            $form.submit();
          }, 300);
          return false;
        }

        //保存cookie
        topbar.top.joinHistory(keyword)
      });

      $(document).on("click", function(e) {
        var obj1 = $(e.target).parents(".sf_text");
        var obj2 = $(e.target).parents(".search_gs");
        if (obj1.length || obj2.length) {
          return;
        }
        $(".search_data").remove();
      });
    },

    statistics: function(keyword) {
      var $ele = $(".history_data_list .hover");
      //TODO className目前只写了单品，type为 'discount' 'sp' 'post' 'guide'
      var statisticsName =
        topbar.searchType == "sp" ? "sp_search_event" : "search_event";
      const type = topbar.top.search.getSearchCategory();

      if ($ele.length) {
        if ($ele.is(".sj")) {
          // 联想搜索词埋点
          var position = $("#suggestion").attr("position");
          var original = $("#suggestion").attr("original");
          statistics.action_statics(`search--default-associate---${type}`, "", {
            data: {
              keyword: keyword,
              position: position - 0 + 1,
              original: original
            },
            name: statisticsName
          });
        }
        if ($ele.is(".hj")) {
          // 历史记录埋点，并且统计了以后，不再统计submit的埋点了
          statistics.action_statics(`search--default-history---${type}`, "", {
            data: { keyword: keyword },
            name: statisticsName
          });
        }
      } else {
        // 普通埋点
        if (
          ($(".cn_recommend_list .hover").length &&
            $(".cn_recommend_list .hover").is(".hj")) ||
          ($(".cn_history_data_list .hover").length &&
            $(".cn_history_data_list .hover").is(".hj")) ||
          $(".recommend_click_done").length
        ) {
          //推荐词不再统计一次
        } else {
          statistics.action_statics(`search--default-button_enter---${type}`, "", {
            data: { keyword: keyword },
            name: statisticsName
          });
        }
      }
    },

    getHistroyFromCookie: function() {
      const history = topbar.top.readSearchHistory()
      var histroyHtml = "";
      if (history.length) {
        if (window.dmLang == "en") {
          histroyHtml +=
            '<div class="history_data_clear"><span id="clearHistory">Clear</span>Search History</div>';
        } else {
          // 这里保留之前的逻辑，感觉永远都是走history.length > 0
          var historyBtn =
            history.length > 0
              ? '<div class="search-history-btn"><p class="search-show-more">展开全部<i class="dm-icon dm-icon-jiantou-xia search-history-more"></i></p><p class="search-icon-delete"><i class="dm-icon dm-icon-shanchu"></i></p><p class="search-history-clear">确认清空</p></div>'
              : '<div class="search-history-btn"><p class="search-icon-delete search-delete-show"></p><p class="search-history-clear">确认清空</p></div>';
          histroyHtml += `<div class="cn_history_data_clear"><b>最近搜索</b>${historyBtn}</div>`;
        }
        var innerHtml = "";
        $.each(history, function(index, value) {
          let encodeValue = htmlEncodeByRegExpAfter(htmlEncodeByRegExp(value));
          innerHtml += `<li class="hj">${encodeValue}<span style="display:none;">${encodeValue}</span></li>`;
        });

        if (window.dmLang === "en") {
          histroyHtml += `<ul class="history_data_list">${innerHtml}</ul>`;
        } else {
          histroyHtml += `<div class="cn_history_data_wrap"><ul class="cn_history_data_list clearfix">${innerHtml}</ul></div>`;
        }
      }
      return histroyHtml;
    },

    storeHtml: "",

    getHotStores: function() {
      var storeHtml = this.storeHtml;
      if (storeHtml) {
        return storeHtml;
      }

      var _this = this;
      $.post(
        topbar.popular_store_url,
        function(res) {
          if (res.data.stores && res.data.stores.length) {
            var innerHtml = "";
            var origin = window.location.origin.replace(/my|sso/gi, "cn");
            res.data.stores.forEach(store => {
              innerHtml += `<li><a href="${origin}/cn/stores/${
                (store.urlName||'').toLowerCase()
              }" store="${store.id}"><img src="${store.logoUrlShow}"></a></li>`;
            });
            storeHtml = `<div class="cn_search_title">热门商家</div><ul class="cn_store_list clearfix">${innerHtml}</ul>`;

            $(".j-search-popular-store").html(storeHtml);

            _this.storeHtml = storeHtml;
          }
        },
        "json"
      );

      return '<div class="j-search-popular-store"></div>';
    },

    getRecommendWord: function(recommendData) {
      var recommendHtml = "";
      if (recommendData && recommendData.length) {
        recommendHtml += "<div class='cn_search_title'>热门搜索</div>";
        var innerHtml =
          "<li class='hj'><div class='recommend_wrap'><p>" +
          topbar.placeHolder +
          "</p></div><span style='display:none;'>" +
          topbar.placeHolder +
          "</span></li>";
        $.each(recommendData, function(index, value) {
          if (value.name != topbar.placeHolder) {
            innerHtml +=
              "<li class='hj'><div class='recommend_wrap'><p>" +
              value.name +
              "</p></div><span style='display:none;'>" +
              value.name +
              "</span></li>";
          }
        });
        recommendHtml +=
          "<ul class='cn_recommend_list clearfix'>" + innerHtml + "</ul>";
      }
      return recommendHtml;
    },

    randomShowWord: function(data, length) {
      var randoms = [];
      var len = data.length;
      while (true) {
        var isExists = false;

        var random = data[parseInt(len * Math.random())];
        // 判断当前随机数是否已经存在
        for (var i = 0; i < randoms.length; i++) {
          if (random.name === randoms[i].name) {
            isExists = true;
            break;
          }
        }
        // 如果不存在，则添加进去
        if (!isExists) randoms.push(random);
        // 如果有10位随机数了，就跳出
        if (randoms.length === length) break;
      }
      return randoms;
    },

    getRandomWord: function(data) {
      var wordData = [],
        highWord = [],
        lowWord = [],
        recommendHis = [];
      const history = topbar.top.readSearchHistory()
      //推荐词里面排重历史记录
      $.each(data, function(i, val) {
        if (val.priority == 100) {
          highWord.push(val);
        } else {
          lowWord.push(val);
        }
        if (history.length) {
          $.each(history, function(i, name) {
            if (name == val.name) {
              val.priority == 100 ? highWord.pop() : lowWord.pop();
              recommendHis.push(name);
            }
          });
        }
      });

      if (highWord.length >= 10) {
        wordData = this.randomShowWord(highWord, 10);
      } else {
        if (highWord.length + lowWord.length >= 10) {
          var lowWord = this.randomShowWord(lowWord, 10 - highWord.length);
          wordData = highWord.concat(lowWord);
        } else {
          var cover = recommendHis.slice(
            0,
            10 - highWord.length - lowWord.length
          );
          var newCover = [];
          $.each(cover, function(i, name) {
            newCover.push({ name: name });
          });
          wordData = highWord.concat(lowWord).concat(newCover);
        }
      }
      return wordData;
    },

    dealRecommendWord: function(data) {
      if (data && data.length) {
        var len = data.length;
        if (len > 10) {
          return this.randomShowWord(this.getRandomWord(data), 10);
        } else {
          return this.randomShowWord(data, len);
        }
      }
    },

    _onSearchData: function(sendDataBackFun, inputData) {
      var inputAc = $(inputData);
      var showDataDiv = "";
      var key = "";
      var hover = false;
      var _this = this;
      //输入框输入后，读取数据，并且展开匹配内容
      inputAc.on("keyup", function(event) {
        //键盘选择下拉项
        if (event.keyCode == 38 || event.keyCode == 40) {
          var current = showDataDiv.find("li.hover");
          if (event.keyCode == 38) {
            if (current.length > 0) {
              var prevLi = current.removeClass("hover").prev();
              //若不存在，选择相邻ul
              if (prevLi.length == 0) {
                prevLi = current
                  .parent("ul")
                  .prev()
                  .prev()
                  .find("li:last");
              }
              //若相邻ul完毕后，取第一个
              if (prevLi.length == 0) {
                prevLi = showDataDiv.find("li:last");
              }
            } else {
              var prevLi = showDataDiv.find("li:last");
            }
            prevLi.addClass("hover");
            if (prevLi.hasClass("sjkey") || prevLi.hasClass("hj")) {
              inputAc.val(prevLi.find("span").text());
            }
          } else if (event.keyCode == 40) {
            if (current.length > 0) {
              var nextLi = current.removeClass("hover").next();
              //若不存在，选择相邻ul
              if (nextLi.length == 0) {
                nextLi = current
                  .parent("ul")
                  .next()
                  .next()
                  .find("li:first");
              }
              //若相邻ul完毕后，取第一个
              if (nextLi.length == 0) {
                nextLi = showDataDiv.find("li:first");
              }
            } else {
              var nextLi = showDataDiv.find("li:first");
            }
            nextLi.addClass("hover");
            if (nextLi.hasClass("sjkey") || nextLi.hasClass("hj")) {
              inputAc.val(nextLi.find("span").text());
            }
          }
          //输入字符
        } else {
          var valText = $.trim(inputAc.val());
          if (valText == "") {
            if (
              event.keyCode == 13 &&
              window.countryCode == "US" &&
              window.dmLang == "cn" &&
              topbar.placeHolder
            ) {
              inputAc.val(topbar.placeHolder);
              inputAc.closest("form").submit();
              return;
            }
            if (showDataDiv !== "") {
              showDataDiv.hide();
              if (window.dmLang == "cn") {
                inputAc.removeNextShow();
                inputAc.trigger("focus");
              }
            }
            inputAc
              .parent()
              .find(".search-icon-clear")
              .hide();
            return;
          }
          inputAc
            .parent()
            .find(".search-icon-clear")
            .show();
          sendDataBackFun(valText, inputAc); 
          key = valText;
        }
      });

      inputAc.removeNextShow = function(status) {
        if (!hover) {
          inputAc.next(".search_data").remove();
          if (
            window.dmLang == 'cn' &&
            topbar.recommendWord && 
            !disableSearchRecommends
          ) {
            $(".t_keyword,.search_gs_input")
              .removeClass("ac_loading")
              .attr("placeHolder", topbar.placeHolder);
          }
        }
      };

      inputAc
        .parent()
        .find(".icon-search-top")
        .on("click", function() {
          $(this)
            .parent()
            .find(".search_begin_btn")
            .click();
        });

      inputAc
        .parent()
        .find(".search_begin_btn")
        .on("click", function() {
          var val = inputAc.val();
          if (val.length == 0 && topbar.recommendWord) {
            inputAc.val(topbar.placeHolder);
          }

          $(this)
            .closest("form")
            .submit();
        });

      inputAc
        .parent()
        .find(".search-icon-clear")
        .on("click", function() {
          inputAc.val("");
          inputAc.removeNextShow();
          $(this).hide();
          setTimeout(function() {
            inputAc.trigger("focus");
          }, 150);
        }); //清除按钮功能

      inputAc.on("blur", function() {
        if (topbar.searchRequest != null) {
          topbar.searchRequest.abort();
        }
        setTimeout(function() {
          inputAc.removeNextShow(true);
        }, 100);
      });

      inputAc.on("focus", function() {
        const searchType = topbar.top.search.getSearchCategory();

        inputAc.parents(".search_gs").css("opacity", 1);
        if ($.trim($(this).val())) {
          inputAc
            .parent()
            .find(".search-icon-clear")
            .show();
        }

        if (
          $(this).val() == '' &&
          inputAc.next('.search_data').length < 1 &&
          !disableSearchRecommends
        ) {
          showDataDiv = $("<div class='search_data'></div>");
          inputAc.after(showDataDiv);

          var showDataDivinnerHtml = "";
          // 历史搜索记录
          showDataDivinnerHtml += _this.getHistroyFromCookie();
          //中文页面添加热门商家和热门搜索
          if (window.dmLang == "cn") {
            if (!disableSearchRecommends) {
              showDataDivinnerHtml += _this.getRecommendWord(
                topbar.recommendWord
              );
            }
            if (common.isStoresDisplay) {
              showDataDivinnerHtml += _this.getHotStores();
            }
          }
          showDataDiv.html(showDataDivinnerHtml);

          if (
            typeof searchHistoryData != "undefined" &&
            searchHistoryData != null &&
            searchHistoryData != "" &&
            searchHistoryData.length == 1
          ) {
            $(".search-show-more").hide();
            $(".search-icon-delete").show();
          }
          if ($(".cn_history_data_list").length) {
            if ($(".cn_history_data_list").height() > 40) {
              $(".search-show-more").show();
            } else {
              $(".search-icon-delete").show();
            }
          }
          showDataDiv
            .find("li")
            .on("mouseenter", function() {
              showDataDiv.find("li").removeClass("hover");
              $(this).addClass("hover");
            })
            .on("mouseleave", function() {
              $(this).removeClass("hover");
            });

          showDataDiv.find("li.hj").on("click", function() {
            var keyword = $(this)
              .find("span")
              .text();
            inputAc.val(keyword);
            inputAc.css("color", "rgb(0, 0, 0)");
            if (
              $(this)
                .parent()
                .is(".cn_history_data_list")
            ) {
              // 历史记录埋点
              statistics.action_statics(`search--default-history---${searchType}`, "", {
                data: { keyword: keyword }
              });
            }
            if (
              $(this)
                .parent()
                .is(".cn_recommend_list")
            ) {
              // 推荐词埋点
              statistics.action_statics(
                `search--default-recommendKeywords---${searchType}`,
                "",
                { data: { keyword: keyword } }
              );
            }
            var _this = this;
            setTimeout(function() {
              $(_this)
                .closest("form")
                .submit();
            }, 200);
          });

          inputAc
            .parent()
            .find(".cn_store_list a")
            .on("click", function() {
              var storeId = $(this).attr("store");
              statistics.action_statics(`search--default-store---${searchType}`, "", {
                data: { storeId: storeId }
              });
            });

          inputAc
            .parent()
            .find(".search_begin_btn")
            .on("mouseenter", function() {
              hover = true;
            })
            .on("mouseleave", function() {
              hover = false;
            });

          showDataDiv.on("mouseenter", function() {
            hover = true;
          });
          showDataDiv.on("mouseleave", function() {
            hover = false;
          });

          window.dmLang == "cn"
            ? showDataDiv.find(".search-history-clear").on("click", function() {
                $.cookie("searchHistoryData", "", {
                  path: "/",
                  expires: -1,
                  domain: document.domain.slice(document.domain.indexOf("."))
                });
                $(".search_data")
                  .find(".cn_history_data_clear")
                  .remove();
                $(".search_data")
                  .find(".cn_history_data_wrap")
                  .remove();
                inputAc.trigger("focus");
                return false;
              })
            : showDataDiv.find("#clearHistory").on("click", function() {
                $.cookie("searchHistoryData", "", {
                  path: "/",
                  expires: -1,
                  domain: document.domain.slice(document.domain.indexOf("."))
                });
                inputAc.next(".search_data").remove();
              });

          $(".search-show-more").on("click", function() {
            $(this).hide();
            $(".cn_history_data_wrap").addClass("cn_list_more");
            $(".search-icon-delete").show();
          });
          $(".search-icon-delete").on("click", function() {
            $(this).hide();
            $(this)
              .next()
              .show();
          });
        }
      });

      //生成层及内容
      inputAc.disData = function(obj) {
        if (obj.status != 1 || inputAc.val() == "") {
          inputAc.next(".search_data").remove();
          $("#t_keyword").removeClass("ac_loading");
          if (window.dmLang == "cn") {
            inputAc.trigger("focus");
          }
          return;
        }
        var showDataDivinnerHtml = "";
        //index
        var innerHtml = "";
        if (obj.data.suggestList && obj.data.suggestList.length) {
          if (window.dmLang == "en") {
            obj.data.suggestList = obj.data.suggestList.splice(0, 7);
          }
          $.each(obj.data.suggestList || [], function(name, item) {
            var value = htmlEncodeByRegExp(item.keyword);
            var value_b = htmlEncodeByRegExpAfter(value.replace(key, "<b>" + key + "</b>"));
            var storeLogo = '';
            var iconClass = 'search';
            if (item.type === 'history') {
              iconClass = 'clock';
            }
            if (item.storeInfo) {
              storeLogo = '<span><img src="' + item.storeInfo.logoUrl + '" /></span>'
            }
            var icon = '<span class="' + iconClass + '"></span>';
            innerHtml +=
              "<li class='sjkey' index='" + name + "'><div>" + icon +
              value_b +
              '<span style="display:none;">' +
              value +
              '</span></div>' + storeLogo + '</li>';
          });
        }
       
        // 有搜索内容时，才展示搜索列表
        if (innerHtml) {
          showDataDivinnerHtml +=
          "<ul class='history_data_list'>" + innerHtml + "</ul>";
        }

        //商家数据
        innerHtml = "";
        if (obj.data.stores && obj.data.stores.length) {
          $.each(obj.data.stores, function(name, value) {
            var store_name = value.name;
            store_name = "<p>" + store_name + "</p>";
            innerHtml +=
              '<li><a data-storeid="' +
              value.id +
              '" href="' +
              decodeURIComponent(topbar.store_detail_url).replace(
                "@@@",
                (value.urlName||'').toLowerCase()
              ) +
              '">\
                                <div class="store-img-wrap"><img src="' +
              value.logoUrl +
              '"></div>' +
              store_name +
              "</a></li>";
          });
          if (common.isStoresDisplay) {
            if (window.dmLang == "en") {
              showDataDivinnerHtml +=
                "<div class='brand_list_title'><i></i>Search deals by store</div><ul class='brand_list'>" +
                innerHtml +
                "</ul>";
            } else {
              // showDataDivinnerHtml +=
              //   "<div class='brand_list_title'><i></i>按商家搜索折扣</div><ul class='brand_list'>" +
              //   innerHtml +
              //   "</ul>";
            }
          }
        }

        if (inputAc.next(".search_data").length < 1) {
          showDataDiv = $("<div class='search_data'></div>");
          inputAc.after(showDataDiv);
        }
        if (obj.data.suggestList && obj.data.suggestList.length > 0) {
          showDataDiv.show();
        } else {
          showDataDiv.hide();
        }

        if (showDataDivinnerHtml != "") {
          showDataDiv.html(showDataDivinnerHtml);
        }
        $("#t_keyword").removeClass("ac_loading");

        //为下拉选项绑定鼠标事件
        showDataDiv
          .find("li")
          .on("mouseenter", function() {
            showDataDiv.find("li").removeClass("hover");
            $(this).addClass("hover");
          })
          .on("mouseleave", function() {
            $(this).removeClass("hover");
          })
          .addClass("sj");
        showDataDiv.find("li.sjkey").on("click", function() {
          var position = $(this).attr("index");
          var original = $("#t_keyword").val();
          inputAc.val(
            $(this)
              .find("span")
              .text()
          );
          $("#frmSearch").append(
            '<span id="suggestion" style="display: none" position="' +
              position +
              '" original="' +
              original +
              '"></span>'
          );
          $(this)
            .closest("form")
            .submit();
        });
        showDataDiv.on("mouseenter", function() {
          hover = true;
        });
        showDataDiv.on("mouseleave", function() {
          hover = false;
        });
        // 点击商家统计，暂时不做埋点
        // showDataDiv.find('.sj a[data-storeid]').on('click', function(e) {
        //   var storeId = $(this).data('storeid');
        // statistics.action_statics('search--default-store-', '', {data: {storeId: storeId}});
        // e.stopPropagation();
        // });
      };
    }
  },

  hotSearchText: function() {
    window.dmLazyCall(function() {
      topbar.top.hotSearchAction()
    })
  },

  //页面头部热门搜索关键字js输出
  hotSearchAction: function() {
    var cnHtml =
      common.storesLink != "" ? common.storesLink[window.dmLang] : "";
    const searchType = topbar.top.search.getSearchCategory();

    if (
      window.dmLang == "cn" &&
      !disableSearchRecommends &&
      !topbar.recommendWord
    ) {
      $.ajax({
        type: "POST",
        url: topbar.recommend_url,
        dataType: "json",
        success: function(data) {
          var innerHtml = [];
          topbar.recommendWord = topbar.top.search.dealRecommendWord(data);
          topbar.recommendWord &&
            topbar.recommendWord.length &&
            $.each(topbar.recommendWord, function(name, value) {
              innerHtml.push('<a href="javascript:;">' + value.name + "</a>");
            });
          innerHtml.splice(0, 1); // 去掉第一个, 因为和输入框默认搜索词重复了
          cnHtml += '<div class="recommend_word">' + innerHtml.join('') + "</div>";

          $(".sf_word").append(cnHtml);

          if (topbar.recommendWord && topbar.recommendWord.length) {
            topbar.placeHolder = topbar.recommendWord[0].name;
            $(".t_keyword,.search_gs_input").attr("placeHolder", topbar.placeHolder);
          }

          $(".recommend_word a").on("click", function() {
            var keyword = $(this).text();
            $(".t_keyword").val(keyword);
            $(this).addClass("recommend_click_done");
            statistics.action_statics(
              `search--default-recommendKeywords---${searchType}`,
              "",
              {
                rip: 'search_list',
                data: { keyword }
              }
            );
            $("#frmSearch").submit();
          });
        }
      });

      return;
    }

    if (window.dmLang == "cn") {
      $(".sf_word").append(cnHtml);
      return;
    }

    if (window.dmLang == "en") {
      //英文热门搜索链接
      var enHtml =
        common.storesLink != "" ? common.storesLink[window.dmLang] : "";
      enHtml = enHtml + "<span>"; // 为了包裹下面的热搜词链接
      enHtml =
        enHtml +
        '<a href="' +
        decodeURIComponent(topbar.store_detail_url).replace(
          "@@@",
          "nordstrom"
        ) +
        '" class="topa">Nordstrom</a>';
      enHtml =
        enHtml +
        '<a href="' +
        decodeURIComponent(topbar.store_detail_url).replace("@@@", "amazon") +
        '" class="topa">Amazon</a>';
      enHtml =
        enHtml +
        '<a href="' +
        topbar.host +
        'credit-cards" class="topa">Credit Cards</a>';
      // enHtml = enHtml + '<a href="' + decodeURIComponent(topbar.store_detail_url).replace('@@@', '6PM-com') +'" class="topa" target="_blank">6pm.com</a>';
      enHtml =
        enHtml +
        '<a href="' +
        topbar.category_url.replace(
          "@@@",
          "electronics-computers/laptops-desktops"
        ) +
        '" class="topa">Laptops</a>';
      enHtml = enHtml + "</span>";

      $(".sf_word").append(enHtml);

      return;
    }
  },

  //用户登录按钮
  loginBtn: function() {
    //login
    $("#sign_in_top,.icon-pic-reg")
      .on("mouseenter", function() {
        $("#sign_in_top")
          .stop(false, true)
          .addClass("link_reg_ahover")
          .end();
        $(".icon-pic-reg")
          .stop(false, true)
          .addClass("pic_reg_hover")
          .end();
        $(".icon-pic-reg").css({ opacity: "0.9" });
      })
      .on("mouseleave", function() {
        $("#sign_in_top")
          .stop(false, true)
          .removeClass("link_reg_ahover")
          .end();
        $(".icon-pic-reg")
          .stop(false, true)
          .removeClass("pic_reg_hover")
          .end();
        $(".icon-pic-reg").css({ opacity: "1" });
      });
  },

  showLoginBox: function(loginurl, referer, close, options = {}) {
    let position = "fixed";
    // ipad兼容处理登录弹窗为absolute，并移动到屏幕中心
    if (/ipad/i.test(navigator.userAgent)) {
      position = "absolute";
      const moveX = document.documentElement.clientWidth - window.innerWidth;
      // ipad手动缩放过，处理位置
      const $w = $(window);
      if (moveX > 0) {
        $w.scrollLeft(moveX / 2);
      }
      $w.scrollTop(
        (document.documentElement.clientHeight - window.innerHeight) / 2
      );
    }
    $(
      `<div id='loginiframe' ${
        options.class ? `class="${options.class}"` : ""
      } style='position: ${position}'><iframe width='500' height='620' allowTransparency frameborder='0' src='${loginurl}&referer=${referer}&site=${(
        window.countryCode || ""
      ).toLowerCase()}'></iframe><div class='defBg'></div>${
        close
          ? "<span onclick='topbar.top.closeLogin()' class='login-close'><i></i></span>"
          : ""
      }</div><div id='zzbg'></div>`
    ).appendTo("body");
    $("body").css({ overflowX: "hidden" });
    // 加载完成后显示iframe页面内容，并移除背景
    $("#loginiframe iframe").on("load", function() {
      $("#loginiframe iframe").show();
      setTimeout(function() {
        $("#loginiframe .defBg").remove();
      }, 500);
    });
    if (~navigator.appVersion.indexOf("MSIE 9.0")) {
      $("#loginiframe").show();
    } else {
      $("#loginiframe").addClass("showLogin");
    }
    $("#zzbg").addClass("showLogin");
  },

  // 登录并成功以后刷新
  loginAndRefresh: function(closeable) {
    // 必须先登录
    if (!$.cookie("usertoken")) {
      // 获取到已登录后，获取一下用户信息，不刷新页面
      window.addEventListener("message", function(event) {
        if (event.data === "LOGIN_SUCCESS") {
          location.reload();
        }
      });
      setTimeout(function() {
        var referer = window.parent.location.href;
        referer = encodeURIComponent(referer);
        topbar.top.showLoginBox(topbar.login_url, referer, closeable);
      }, 500);
    } else {
      return;
    }
  },

  //打开登录框
  openLogin: function(lan) {
    // 获取到已登录后，获取一下用户信息，不刷新页面
    window.addEventListener("message", function(event) {
      if (event.data === "LOGIN_SUCCESS") {
        topbar.top.closeLogin();
        topbar.top.closeLocal();
        topbar.user.getUserInfo(
          window.index.pageType.indexOf("baoliao") > -1 ? "full" : undefined
        );
      }
    });
     window.addEventListener("message", function(event) {
       if (event.data && event.data.googleOAuth2Url) {
        // console.log(event.data);
        const winWidth = 500;
        const winLeft = ($('body').width() - winWidth)/2;
        const windowFeatures = `width=${winWidth},height=600,top=100,left=${winLeft}`;
        window.open(event.data.googleOAuth2Url,'_blank',windowFeatures);
       }
    });
    $(document).on("click", ".sign_in_login", function() {
      var referer = window.parent.location.href;
      referer = encodeURIComponent(referer);
      topbar.top.showLoginBox(topbar.login_url, referer, true);
    });

    $("#sign_in_top, a.icon-pic-reg").on("click", function() {
      var referer = window.parent.location.href;
      referer = encodeURIComponent(referer);
      topbar.top.showLoginBox(topbar.login_url, referer, true);
    });
  },

  //关闭登录框
  closeLogin: function() {
    $("#zzbg")
      .removeClass("showLogin")
      .addClass("hideLogin");
    $("#loginiframe")
      .removeClass("showLogin")
      .addClass("hideLogin");
    setTimeout(function() {
      $("#loginiframe").remove();
      $("#zzbg").remove();
      $("body").css({ overflow: "auto" });
    }, 500);
  },

  //去掉LOCAL
  closeLocal: function() {
    jQuery(".submit_btn_unlog")
      .text("评论")
      .removeClass("sign_in_login")
      .addClass("submit_btn");
  },

  //注册
  openRegister: function(lan) {
    $("#register_up_top").on("click", function() {
      var referer = window.parent.location.href;
      referer = encodeURIComponent(referer);
      // cn站访问，弹出实名认证框
      if (window.fromChinaSite == 1) {
        const regUrl = topbar.login_url.replace(
          "login/layer",
          "register/phone"
        );
        return topbar.top.showLoginBox(regUrl, referer, true, {
          class: "reg-iframe"
        });
      }
      if (lan == "cn") {
        window.open(
          topbar.user.ucenter_host + "register?language=cn&referer=" + referer
        );
      } else if (lan == "en") {
        window.open(
          topbar.user.ucenter_host + "register?language=en&referer=" + referer
        );
      }
    });
  }
};

//二级导航
topbar.nav = {
  //二级导航子分类显示
  subNavShow: function() {
    //select menu
    $(".one_level")
      .on("mouseenter", function() {
        $(this)
          .children(".droplist")
          .show();
        $(this)
          .find(".more_arrow i")
          .show();
        optimizeMore(this);
      })
      .on("mouseleave", function() {
        $(this)
          .children(".droplist")
          .hide();
        $(this)
          .find(".more_arrow i")
          .hide();
        resetMore(this);
      });

    // $(".nav-local-li")
    //   .on("mouseenter", function() {
    //     $(".nav-local-list").show();
    //   })
    //   .on("mouseleave", function() {
    //     $(".nav-local-list").hide();
    //   });

    //优化更多模块下拉列表显示
    function optimizeMore(ele) {
      if ($(ele).hasClass("more")) {
        //是否处于滚动后状态
        var scroll_drop = $(".nav_fixed .more .droplist.wid_menu").length > 0;
        //处理更多菜单中元素宽度问题
        var findstr =
          ".more .droplist.wid_menu>p:first-child>span:not(.squeeze)>a";
        if (scroll_drop)
          findstr = ".more .droplist.wid_menu>p:first-child>span>a";
        var _ws = [0, 0, 0];
        $(findstr).each(function(i, e) {
          var w = $(e).width();
          if (w > _ws[i % 3]) _ws[i % 3] = w;
        });
        $(findstr).each(function(i, e) {
          $(e).width(_ws[i % 3]);
        });
        $(ele)
          .children(".droplist")
          .width(_ws[0] + _ws[1] + _ws[2] + 60);

        //更多菜单根据屏幕位置进行优化
        if (!scroll_drop) {
          var offX =
            $(ele)
              .children(".droplist")[0]
              .getBoundingClientRect().right - $(window).width();
          var maxOff = 110;
          var minOff = -30;
          if (window.dmLang !== "cn") {
            maxOff = 200;
            minOff = 25;
          }
          if (offX > maxOff) offX = maxOff;
          if (offX < minOff) offX = minOff;
          $(ele)
            .children(".droplist")
            .css("left", -(offX + 182) + "px");
        } else {
          if (window.dmLang !== "cn")
            $(ele)
              .children(".droplist")
              .css("left", "-182px");
        }
      }
    }

    //恢复more菜单默认值
    function resetMore(ele) {
      $(".more .droplist.wid_menu>p:first-child>span>a").each(function(i, e) {
        $(e).css("width", "");
      });
      //恢复至默认
      if ($(ele).hasClass("more"))
        $(ele)
          .children(".droplist")
          .css("left", "-132px");
    }
  },

  //搜索
  search: function() {
    const searchType = topbar.top.search.getSearchCategory();
    
    //fill in the blank the keyword
    if (typeof index.keyword != "undefined") {
      $(".search_gs_input").val(index.keyword);
    }
    
    $(".search_gs_input")
      .on("focus", function() {
        $(this).css("color", "#000");
        $(this)
          .parents(".search_gs")
          .addClass("focusb");
        var textVal = $(this).val();
        if (textVal == this.defaultValue) {
          $(this).val("");
        }
        if (!disableSearchRecommends) {
          $(this)
            .next(".search_data")
            .show();
        }
      })
      .on("blur", function() {
        $(this).css("color", "#d1d1d1");
        $(this)
          .parents(".search_gs")
          .removeClass("focusb");
      });

    $(".history_data_list li")
      .on("mouseenter", function() {
        $(this).addClass("hover");
      })
      .on("mouseleave", function() {
        $(this).removeClass("hover");
      });

    var _formZkSearchLoged = false;
    $("#formZkSearch").on("submit", function(e) {
      var keyword = $(this)
        .find("[name=q]")
        .val()
        .trim();
      if (keyword == $("#t_keyword").attr("defaultvalue") || keyword == "") {
        return false;
      }
      // 搜索埋点
      if (!_formZkSearchLoged) {
        var $ele = $(".history_data_list .hover");
        if ($ele.length) {
          if ($ele.is(".sj")) {
            // 联想搜索词埋点
            statistics.action_statics(`search--default-associate---${searchType}`, "", {
              rip: 'search_list',
              data: { keyword: keyword }
            });
          }
          if ($ele.is(".hj")) {
            // 历史记录埋点，并且统计了以后，不再统计submit的埋点了
            statistics.action_statics(`search--default-history---${searchType}`, "", {
              rip: 'search_list',
              data: { keyword: keyword }
            });
          }
        } else {
          // 普通埋点
          if (
            ($(".cn_recommend_list .hover").length &&
              $(".cn_recommend_list .hover").is(".hj")) ||
            ($(".cn_history_data_list .hover").length &&
              $(".cn_history_data_list .hover").is(".hj"))
          ) {
            //推荐词不再统计一次
          } else {
            statistics.action_statics(`search--default-button_enter---${searchType}`, "", {
              rip: 'search_list',
              data: { keyword: keyword }
            });
          }
        }
        var $form = $(this);
        // 防止埋点请求被浏览器cancel
        setTimeout(function() {
          _formZkSearchLoged = true;
          $form.submit();
        }, 300);
        return false;
      }
    });
  }
};

topbar.user = {
  ucenter_host: "",
  userId: "",
  userEmail:''
};

topbar.user.getUserInfo = function(type = undefined) {
  if (!$.cookie("usertoken")) {
    topbar.user.handleUserInfo({});
    return;
  }
  $.ajax({
    url: topbar.user.ucenter_host + "api/get-user-info?callback=?",
    data: {
      type
    },
    dataType: "jsonp"
  }).done(function(data) {
    topbar.user.handleUserInfo(data);
  }).catch(function(e){
    if (e && e.statusText == 'error') {
      $(window).trigger('usernotlogin');
    }
  });
};

topbar.user.handleUserInfo = function(data) {
  if (data && data["name"]) {
    $("#DLH")
      .find(".user_name a")
      .html(decodeURI(data["name"]));
    $("#DLH").show();
    $("#DLQ").hide();
    $(".login_after_box").show();
    $(".login_before").hide();
    topbar.user.isLogin = true;
    if (data.commentDisallowed) {
      topbar.user.commentDisallowed = data.commentDisallowed;
    }
    topbar.user.userName = data["userName"];
    topbar.user.userId = data["id"];
    topbar.user.userEmail = data["email"];
    topbar.user.lang = data["lang"];
    
    $(".login_user_name").html(
      `<span class="name${data['userName'].replace(/[\u0391-\uFFE5]/g, "aa").length > 16 ? ' show-mask' : ''}">${data["userName"]}</span>
      <i class="dm-icon dm-icon-jiantou-xia"></i>`
    );
    // 获取到用户ID以后，设置GA userId
    window.gastatistics &&
      gastatistics.action.ga("set", "userId", topbar.user.userId);
    // 获取到用户ID以后，设置dmtrk userId
    window.dmtrk && typeof window.dmtrk === 'function' && window.dmtrk("setUserId", topbar.user.userId);
    $(window).trigger('userlogged');
  } else {
    topbar.user.isLogin = false;
    $(window).trigger('usernotlogin');
  }
  $.cookie('loginPage',null);
  if ($.cookie('google-logged')) {
    $('.other_zh .g_id_signin').css('display','block');  
    $('.other_zh .google').css('display','none');
    if ($('.g_id_signin iframe').length) {
      $('.g_id_signin iframe').attr('src',$('.g_id_signin iframe').attr('src'));
    }
  }else{
    $('.other_zh .g_id_signin').css('display','none');  
    $('.other_zh .google').css('display','block');
  }

  if (data && data["avatar"]) {
    data["avatar"] = data["avatar"].replace("http://", "//");
    topbar.user.avatar = data["avatar"];
    $(".login_user_avatar").attr("src", data["avatar"]);
  } else {
    topbar.user.avatar =
      (location.href.indexOf("https://") === 0 ? "https" : "http") +
      "://www.dealmoon.com/assets/image/guest.jpg";
  }

  if (data && data["baoliao"]) {
    var html = `
        <div class="user_infor_box">
          <p class="user_pic"><img src="${data["avatar"]}"></p>
          <p class="user_name">${data["userName"]}</p>
          <ul class="user_award">
            <li><span>${data["baoliao"]["disclosure"]}</span>爆料</li>
            <li><span>${data["baoliao"]["card"]}</span>礼卡</li>
            <li><span>${data["baoliao"]["gold"]}</span>金币</li>
          </ul>
        </div>
      `;
    $(".baoliao_index_callback").html(html);
    $(".grap_submit_btn").removeClass("sign_in_login");
  }

  if (data && data["favoriteNum"]) {
    var favnum = parseInt(data["favoriteNum"]);
    if (favnum > 99) {
      favnum = "99+";
    }
    favnum = "(" + favnum + ")";
    $(".login_user_favnum").html(favnum);
  }
  if (topbar.curPage == "deal") {
    //攻略和众测 评论
    if (typeof common != "undefined") {
      common.comment.userNameSet();
    }
  }
};

topbar.user.getUserName = function() {
  if (!$.cookie("usertoken")) {
    return window.dmLang == "en" ? "guest" : "匿名用户";
  }
  return topbar.user.userName;
};

topbar.user.getUserId = function() {
  return topbar.user.userId
    ? topbar.user.userId
    : readCookie("usertoken").split("|")[0];
};

topbar.toCHT = function() {
  if (topbar.isCHT) {
    helper.toCHT();
  }

  //bind to CHT button
  $("#CHT").on("click", function() {
    window.open(topbar.bg_host);
    return;
  });
};

//切换语言时做的跳转cookie处理
topbar.recordLang = function(lang) {
  window.enTipOut && clearTimeout(window.enTipOut);
  var domain = location.host.substr(location.host.indexOf(".") + 1);
  $.cookie("manualSelection", lang, { expires: 365, domain: domain, path: '/', secure: true });
  $.cookie("enSiteTip", "hide", { expires: 365, domain: domain, path: '/', secure: true });
};

window.topbar = topbar;

export default topbar;
