/**
 * fly
 */
var fly = require("flyio");
//设置请求基地址
fly.config.baseURL = "https://ws.it1.dealmoon.net/";

//添加请求拦截器
fly.interceptors.request.use(request => {
  if(window.isOnline) {
    request.baseURL = `${location.protocol}//ws.${location.host.split('.').slice(1).join('.')}`;
  }
  const usertoken = decodeURIComponent($.cookie("usertoken") || "");
  
  //给所有请求添加自定义header
  let url = request.url;
  url += url.indexOf("?") == -1 ? "?" : "&";
  url += `dm_token=${usertoken}`;

  request.url = url;
  request.headers["Content-Type"] = "application/json";
  request.headers["token"] = usertoken;
  request.headers["x-dm-token"] = usertoken;
  request.headers["x-dm-user-token"] = usertoken;
  request.headers["X-Requested-With"] = "XMLHttpRequest";
  request.headers["x-dm-authorization"] = $.cookie("BearerToken") || "";

  //打印出请求体
  return request;
});

//添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  response => {
    //只将请求结果的data字段返回
    return response.data;
  },
  err => {
    //发生网络错误后会走到这里
    //return Promise.resolve("ssss")
  }
);

export default fly;
