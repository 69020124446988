
const PREV_PAGE_STORAGE_KEY = 'dm-ga-prev-page-name';
const CURRENT_PAGE_STORAGE_KEY = 'dm-ga-current-page-name';
let storage;
try {
  if (window.localStorage) {
    storage = window.localStorage;
  }
} catch (err) {
  console.error('localStorage failed.');
}
if (typeof window[PREV_PAGE_STORAGE_KEY] === 'undefined') {
  if (storage && typeof storage.getItem === 'function') {
    try {
      window[PREV_PAGE_STORAGE_KEY] = storage.getItem(PREV_PAGE_STORAGE_KEY);
    } catch (error) {
      console.error('get prevpage failed.');
    }
  }
  if (!window[PREV_PAGE_STORAGE_KEY]) {
    window[PREV_PAGE_STORAGE_KEY] = '';
  }
}
function setPrevPage () {
  if (storage && typeof storage.setItem === 'function') {
    try {
      storage.setItem(PREV_PAGE_STORAGE_KEY, window[CURRENT_PAGE_STORAGE_KEY]);
    } catch (err) {
      console.error('set prevpage failed.');
    }
  }
}
export default function (currentPageName) {
  if (typeof currentPageName === 'undefined') {
    currentPageName = '';
  }
  if (typeof window[CURRENT_PAGE_STORAGE_KEY] === 'undefined') {
    document.addEventListener('click', setPrevPage);
  }
  window[CURRENT_PAGE_STORAGE_KEY] = currentPageName;
  setPrevPage();
  return window[PREV_PAGE_STORAGE_KEY];
};
