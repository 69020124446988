import './index.less';
// import { ajax } from 'dm-utils-ts';
// import { deleteCookie } from 'dm-utils-ts/lib/src/cookie';

// document.addEventListener('DOMContentLoaded', function()  {
//   const pathname = this.location.pathname;

//   if (!/^\/[e|c]n\/event\//.test(pathname)) {
//     ajax.get(`/event/list?lang=${window.dmLang}`).then(res => {
//       if (res.status && res.data.hasEvent) {
//         deleteCookie('closeEventTip', location.host.replace('www', ''));

//         let html = res.data.html;

//         if (/\/(guide|local|post\/\d+|product(\/|$)|product-reviews|popular-)/.test(pathname)) {
//           html = html.replace('dm-event-tip-bg', 'dm-event-tip-bg dm-event-tip-bg-big');
//         } else if (/\/post(\/|$)/.test(location.pathname)) {
//           html = html.replace(/(('|")dm-event-tip-bg('|"))/, '$1 style="width: 1180px"');
//         }

//         document.body.firstElementChild.insertAdjacentHTML('beforebegin', html);
//         document.body.classList.add('has-event');
//         document.dispatchEvent(new CustomEvent('event-loaded'), {
//           bubbles: true,    //是否冒泡
//           cancelable: false //是否取消默认事件
//         });
//         const timer = setTimeout(function() {
//           document.querySelector('.dm-event-tip-wrap').style.marginTop = '0';
//           clearTimeout(timer);
//         }, 200);
//       }
//     });
//   }
// });

$(() => {
  $(document).on('click', '.dm-event-tip-wrap .close-event', () => {
    const $dmEvent = $('.dm-event-tip-wrap');
    $dmEvent.css('margin-top', '-32px');
    $.cookie('closeEventTip', 1, { expires: 7, path: '/', secure: true, domain: location.host.replace('www', '') });
    $('body').removeClass('has-event').trigger('event-hided');
    const timer = setTimeout(function() {
      $dmEvent.remove();
      clearTimeout(timer);
    }, 200);
  });
});