/*
 * @Author: Gang Jiang
 * @Date: 2020-07-09 17:11:12
 * @LastEditors: Gang Jiang
 * @LastEditTime: 2020-07-09 17:16:41
 * @Description: 
 */ 

import "./index.less";

(function ($, win, undef) {
  $.toast = function (content, opts) {
    content = content === undef ? "Toast Content" : content;
    opts = $.extend(
      {
        duration: 1500
      },
      opts
    );
    var $ele = $(
      '<div class="dm-toast" style="display: none;">' + content + "</div>"
    );
    $ele
      .appendTo("body")
      .show()
      .addClass("dm-toast--show" + (opts.cl ? " dm-" + opts.cl : ""));
    var hide = function () {
      $ele.removeClass("dm-toast--show");
      setTimeout(function () {
        $ele.remove();
      }, 150);
    };
    if (opts.duration !== 0) {
      setTimeout(hide, opts.duration);
    }
    return hide;
  };
})(jQuery, window, undefined);
