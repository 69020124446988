/*
* @Author: limng
* @Date:   2017-7-12 08:00:00
 * @Last Modified by: liming
 * @Last Modified time: 2019-07-09 18:53:52
* @description: 统一的前台分享组件
*/

import 'dm-toast';
import './index.less';
import { url } from 'dm-utils-ts';

var pugc = window.customPvParam && customPvParam['dimension3'] && /^pgcguide\-/.test(customPvParam['dimension3']) ? 'pgc' : 'ugc';
const GAParameters = {
  // 首页/分类列表页
  home: {
    show: {
      eventCategory: 'dm-deal-click',
      eventAction: 'click-dm-home-feed-share',
      eventLabel: 'pgn:home',
      domainID: 'deal',
      storeName: '.mlist.attr(data-dmt-d-store-name)',
      dealID: 'attr(data-id)'
    },
    copy: {
      eventCategory: 'dm-deal-click',
      eventAction: 'click-dm-home-feed-share-copylink',
      eventLabel: 'pgn:home',
      domainID: 'deal',
      storeName: '.mlist.attr(data-dmt-d-store-name)',
      dealID: 'attr(data-id)'
    }
  },
  // 折扣详情页
  deal_detail: {
    show: {
      eventCategory: 'dm-deal-click',
      eventAction: 'click-dm-dealdetail-share',
      eventLabel: 'pgn:dealdetail',
      domainID: 'deal',
      dealID: 'attr(data-id)',
      storeName: '.area_left.attr(data-dmt-d-store-name)',
    },
    copy: {
      eventCategory: 'dm-deal-click',
      eventAction: 'click-dm-dealdetail-share-copylink',
      eventLabel: 'pgn:dealdetail',
      domainID: 'deal',
      dealID: 'attr(data-id)',
      storeName: '.area_left.attr(data-dmt-d-store-name)',
    }
  },
  // 笔记详情页
  post_detail: {
    show: {
      eventCategory: 'dm-ugcpic-click',
      eventAction: 'click-dm-ugcpicdetail-share',
      eventLabel: 'pgn:ugcpicdetail',
      domainID: 'ugc',
      MoonShowID: 'attr(data-id)'
    },
    copy: {
      eventCategory: 'dm-ugcpic-click',
      eventAction: 'click-dm-ugcpicdetail-share-copylink',
      eventLabel: 'pgn:ugcpicdetail',
      domainID: 'ugc',
      MoonShowID: 'attr(data-id)'
    }
  },
  // 长文章详情页
  guide_detail: {
    show: {
      eventCategory: 'dm-' + pugc + 'guide-click',
      eventAction: 'click-dm-' + pugc + 'guidedetail-share',
      eventLabel: 'pgn:' + pugc + 'guidedetail',
      domainID: pugc,
      GuideID: 'attr(data-id)'
    },
    copy: {
      eventCategory: 'dm-' + pugc + 'guide-click',
      eventAction: 'click-dm-' + pugc + 'guidedetail-share-copylink',
      eventLabel: 'pgn:' + pugc + 'guidedetail',
      domainID: pugc,
      GuideID: 'attr(data-id)'
    }
  },
  // 单品详情页
  product_detail: {
    show: {
      eventCategory: 'dm-sp-click',
      eventAction: 'click-dm-spdetail-share',
      eventLabel: 'pgn:spdetail',
      domainID: 'sp',
      dpID: 'attr(data-id)-${DETAIL_NAME}',
      storeName: '.product-main.attr(data-dmt-d-store-name)'
    },
    copy: {
      eventCategory: 'dm-sp-click',
      eventAction: 'click-dm-spdetail-share-copylink',
      eventLabel: 'pgn:spdetail',
      domainID: 'sp',
      dpID: 'attr(data-id)-${DETAIL_NAME}',
      storeName: '.product-main.attr(data-dmt-d-store-name)'
    }
  },
  zhongce_detail: {
    show: {
      eventCategory: 'dm-zhongce-click',
      eventAction: 'click-dm-zhongcedetail-share',
      eventLabel: 'pgn:zhongcedetail',
      domainID: 'zhongce',
      functionID: 'zhongce-attr(data-id)',
      storeName: 'attr(data-store-name)'
    },
    copy: {
      eventCategory: 'dm-zhongce-click',
      eventAction: 'click-dm-zhongcedetail-share-copylink',
      eventLabel: 'pgn:zhongcedetail',
      domainID: 'zhongce',
      functionID: 'zhongce-attr(data-id)',
      storeName: 'attr(data-store-name)'
    }
  },
  brands_detail: {
    show: {
      eventCategory: 'dm-brand-click',
      eventAction: 'click-dm-brand-share',
      eventLabel: 'pgn:branddetail',
      domainID: 'brand',
      value: 'attr(data-brand-name)'
    },
    copy: {
      eventCategory: 'dm-brand-click',
      eventAction: 'click-dm-brand-share-copylink',
      eventLabel: 'pgn:branddetail',
      domainID: 'brand',
      value: 'attr(data-brand-name)'
    }
  },
  store_detail: {
    show: {
      eventCategory: 'dm-store-click',
      eventAction: 'click-dm-store-deal-share',
      eventLabel: 'pgn:storedetail',
      domainID: 'store',
      storeName: '.mlist.attr(data-dmt-d-store-name)',
      dealID: 'attr(data-id)'
    },
    copy: {
      eventCategory: 'dm-store-click',
      eventAction: 'click-dm-store-deal-share-copylink',
      eventLabel: 'pgn:storedetail',
      domainID: 'store',
      storeName: '.mlist.attr(data-dmt-d-store-name)',
      dealID: 'attr(data-id)'
    }
  },
  store_detail_store: {
    show: {
      eventCategory: 'dm-store-click',
      eventAction: 'click-dm-store-share',
      eventLabel: 'pgn:storedetail',
      domainID: 'store',
      storeName: 'attr(data-store-name)'
    },
    copy: {
      eventCategory: 'dm-store-click',
      eventAction: 'click-dm-store-share-copylink',
      eventLabel: 'pgn:storedetail',
      domainID: 'store',
      storeName: 'attr(data-store-name)'
    }
  },
  hotcomment: {
    show: {
      eventCategory: 'dm-hotcomment-click',
      eventAction: 'click-dm-hotcomment-share',
      eventLabel: 'pgn:hotcommenthome',
      domainID: 'hotcomment'
    },
    copy: {
      eventCategory: 'dm-hotcomment-click',
      eventAction: 'click-dm-hotcomment-share-copylink',
      eventLabel: 'pgn:hotcommenthome',
      domainID: 'hotcomment'
    }
  },
  most_clicked_hot_comments: {
    show: {
      eventCategory: 'dm-hotcomment-click',
      eventAction: 'click-dm-chart-hotcomment-share',
      eventLabel: 'pgn:hotcommentchart',
      domainID: 'hotcomment'
    },
    copy: {
      eventCategory: 'dm-hotcomment-click',
      eventAction: 'click-dm-chart-hotcomment-share-copylink',
      eventLabel: 'pgn:hotcommentchart',
      domainID: 'hotcomment'
    }
  }
};

function getOgMetaContent (property) {
  let meta = document.querySelector('meta[property="og:' + property + '"]');
  if (meta) {
    return meta.getAttribute('content');
  }
}
GAParameters['category'] = GAParameters['home'];

let win = window;
let $win = $(win);
let $doc = $(document);

let FB_APP_ID = 181906041915397;
let DEFAULT_APP_KEY = 801816549;
let DEFAULT_RELATE_UID = 2132734472;

let lastDom;

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return rect.top >= 10 && rect.left >= 10 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /*or $(window).height() */ && rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */;
}

function urlParam(data) {
  var arr = [];
  for (var name in data) {
    arr.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
  }
  return arr.join('&');
}

function getUdid() {
  return $.cookie('udid') || '';
}

function getUserId() {
  var usertoken = $.cookie('usertoken');
  if (!usertoken) return '';
  return usertoken.split('|')[0];
}

function getUtmContent(type, id) {
  return type + '_' + id + '_' + getUdid() + '_' + getUserId();
}

$.fn.dmShare = function(options) {
  var share = new MyShare(this, options);
  return share;
};
function getGATRK(data, $element) {
  var params = {};
  for (var key in data) {
    var value = data[key];
    if (value && typeof value === 'string') {
      value = value.replace(/(\.[^\.]+\.)?attr\(([^\)]+)\)/g, function ($0, $1, $2) {
        var $e = $element;
        if ($1) {
          $e = $e.parents($1.substr(0, $1.length - 1));
        }
        return $e.attr($2) || '';
      }).replace(/\${([^}]+)}/g, function($0, $1) { return window[$1] || ''; });
    }
    params[key] = value;
  }
  params['BusinessUnit'] = /(\/h5)\/local/.test(location.pathname) ? 'local' : 'dm';
  params['Language'] = window.dmLang == 'en' ? 'en' : 'cn';
  var gatrk = gastatistics.action.get_ga_post_parm(params, gastatistics.parm.eventsendparm);
  return gatrk;
}
function setGA(share) {
  var pageType;
  if (share.$element) {
    pageType = share.$element.attr('data-page-type');
  }
  if (!pageType) {
    pageType = window.pageType;
  }
  var data = GAParameters[pageType];
  var gatrk;
  if (data) {
    if (data.show) {
      gatrk = getGATRK(data.show, share.$element);
      share.$share.attr('gatrk', gatrk);
    }
    var $copyButton = share.$share.find('.share-copy-button');
    if (data.copy && $copyButton[0]) {
      gatrk = getGATRK(data.copy, share.$element);
      $copyButton.attr('gatrk', gatrk);
      $copyButton.addClass('event_ga_statistics');
    }
  }
}
// 查询微信是否被禁止
async function weChatShow () {
  let res,
    showWeChatShare = JSON.parse(sessionStorage.getItem('dealMoonShowWeChatShare'));
  if (showWeChatShare === null) {
    try {
      res = await $.ajax({
        type: "GET",
        url: "/www/wechat/show",
        dataType: "JSON"
      });
      if (res.status === 1) {
        showWeChatShare = res.data.showWeChatShare;
        sessionStorage.setItem('dealMoonShowWeChatShare', showWeChatShare);
      }
    } catch (e) {
      showWeChatShare = true;
      console.error(e);
    } 
  }
  return showWeChatShare;
}
let MyShare = function(element, options) {
  var me = this;
  me.$element = element;
  me.$share = element.find('.js_dm_share');
  // me.$share = element.length ? element[0].$share : [];
  me.init(options);
};

MyShare.prototype.init = async function(options) {
  var me = this;
  lastDom = me;

  let x_from_site = window.countryCode;
  if(window.fromChinaSite) {
    x_from_site = 'CN';
  }
  // 处理分享的x_from_site参数
  if (options.pageLink) {
    options.pageLink = url.replaceUrlParam(options.pageLink, 'x_from_site', x_from_site.toLowerCase());
  }
  me.opts = $.extend(
    {
      title: '',
      position: 'up',
      pageLink: '',
      imgUrl: '',
      id: '',
      showWeChatShare: true   // 查询微信分享是否禁止
    },
    options
  );
  // 查询微信是否被禁止
  me.opts.showWeChatShare = await weChatShow();
  try {
    me.opts.weiboParams = window.site.share.weibo;
  } catch (e) {
    me.opts.weiboParams = {
      appkey: DEFAULT_APP_KEY,
      ralateUid: DEFAULT_RELATE_UID
    };
  }
  // if (options.toggleOnClick) {
  //   me.$share = me.$element.find('.js_dm_share');
  // } else {
  [].forEach.call($('.js_dm_share'), (s) => {
    if (s && me.$share && me.$share[0] && s == me.$share[0]) {

    } else {
      s.style.display = 'none';
    }
  });
  if (!me.$share || !me.$share.length) {
    me.$element.append(me.caluPositionCreateHtml());
    me.$share = me.$element.find('.js_dm_share');
    // me.$share = $(me.caluPositionCreateHtml());
    // $(document.body).append(me.$share);
    me.$element[0].$share = me.$share;
    me.bindEvents();
    me.drawQRCode();
    me.copyLinkShare();
  }
  setGA(me);
  me.showBox();
};

//创建dom后考虑到不需要每次计算left top,将计算放在创建dom时,以往的caluSharePosition更改为只控制showORhide
MyShare.prototype.caluPositionCreateHtml = function() {
  let me = this;
  let data = me.opts;
  let boxWidth = 286;
  let boxHeight = 254;
  let offset = 15;
  let posStyle = '';
  switch (data.position) {
    case 'left':
      posStyle = `left:-${boxWidth + offset}px; bottom:-20px`;
      break;
    case 'right':
      posStyle = `right:-${boxWidth + offset}px; bottom:-6px`;
      break;
    case 'rightdown':
      posStyle = `left:-20px; bottom:-${boxHeight + offset}px`;
      break;
    case 'leftdown':
      posStyle = `left:-170px; bottom:-${boxHeight + offset}px`;
      break;
    case 'leftdownplus':
      posStyle = `left:-240px; bottom:-${boxHeight + offset}px`;
      break;
    case 'down':
      posStyle = `left:-95px; bottom:-${boxHeight + offset}px`;
      break;
    case 'rightup':
      posStyle = `left:-16px; bottom:28px`;
      break;
    case 'leftup':
      posStyle = `left:-170px; bottom:28px`;
      break;
    case 'up':
      posStyle = `left:-95px; bottom:28px`;
      break;
    case 'middle':
      posStyle = `left:-70px; bottom:-${boxHeight + offset}px`;
      break;
    default:
      posStyle = `left:-20px; top:-${boxHeight + offset}px`;
  }
  let statrk = data.id ? `share-${data.id}-deal--` : '';
  let weibo = window.dmLang === 'cn' ? '新浪微博' : 'Sina Weibo';
  let wechat = window.dmLang === 'cn' ? '微信扫一扫' : 'Wechat Scan';
  let shareList = window.dmLang === 'cn' ? '分享到' : 'Share list';
  let showWeChatShare = me.opts.showWeChatShare;
  let instagram = '';
  let twitter = '';
  if (window.visitCountry != 'CN') {
    // instagram = `<a href="javascript:;" class="instagram j-instagram event_statics_action" data-from="instagram" rel="nofollow" statrk="${statrk}">Instagram</a>`;
    twitter = `<a href="javascript:;" class="x j-x event_statics_action" data-from="x" rel="nofollow" statrk="${statrk}">X</a>`;
  }
  return `<div class="js_dm_share dm-share ${data.position}" style="display: none; ${posStyle}">
            <div class="share_btn_area">
              <span role="button" class="close"></span>
              <h3 class="share-title">${shareList}</h3>
              <div class="share-channel">
                <div class="area_l">
                  <a href="javascript:;" class="facebook j-facebook event_statics_action" data-from="facebook" rel="nofollow" statrk="${statrk}">Facebook</a>
                  ${twitter}
                  ${instagram}
                  <a href="javascript:;" class="weibo j-weibo event_statics_action" data-from="weibo" rel="nofollow" statrk="${statrk}">${weibo}</a>
                  ${showWeChatShare ? `<a href="javascript:;" class="wechat event_statics_action" data-from="wechat" rel="nofollow" statrk="${statrk}">${wechat}</a>` : ''}
                </div>
                <div class="area_r">
                  <div class="wechat_qrcode j-wechat-qrcode">
                    <div class="qrcode-spin">
                      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="share-copy-link">
                <input type="text" class="share-copy-input" readonly="readonly" value="" />
                <span role="button" class="share-copy-button">Copy</span>
              </div>
            </div>
          </div>`;
};

MyShare.prototype.showBox = function() {
  let me = this;
  let display = me.$share.css('display');
  if (display != 'block') {
    me.$share.show();
    var parm = me.$share.attr("gatrk");
    gastatistics.action.ga_event_statistics(parm);
    // setTimeout(function () {
    //   me.$share.addClass('visible');
    // }, 50);
  }
};

MyShare.prototype.bindEvents = function() {
  var me = this;

  me.$share.on('click', '.j-weibo', e => {
    // e.stopPropagation();
    weiboShare(me.opts);
    me.opts.callback &&
      me.opts.callback({
        type: 'weibo',
        target: e.target
      });
  });

  me.$share.on('click', '.j-facebook', e => {
    // e.stopPropagation();
    fbShare(me.opts);
    me.opts.callback &&
      me.opts.callback({
        type: 'facebook',
        target: e.target
      });
  });

  me.$share.on('click', '.j-x', e => {
    xShare(me.opts);
    me.opts.callback &&
      me.opts.callback({
        type: 'x',
        target: e.target
      });
  });
  me.$share.on('click', '.j-instagram', e => {
    instagramShare(me.opts);
    me.opts.callback &&
      me.opts.callback({
        type: 'instagram',
        target: e.target
      });
  });

  me.$share.on('click', '.close', e => {
    e.stopPropagation();
    // 笔记、攻略广告点击分享不跳转
    const parents = $(e.target).parents('.tongji_post, .gonglue_list_box');
    if (parents.length) {
      e.preventDefault();
    }
    // me.$share.removeClass('visible');
    // setTimeout(function () {
    me.$share.hide();
    // }, 200);
  });

  me.$share.on('click', '.share-copy-button', e => {
    // e.stopPropagation();
    var input = me.$share.find('.share-copy-input')[0];
    if (input) {
      input.select();
      document.execCommand('Copy');
      $.toast(window.dmLang === 'cn' ? '链接已拷贝到剪贴板' : 'Copied to clipboard');
    }
  });

  // if (me.opts.hoverOutHide) {
  //   $(me.opts.clazz).on('mouseleave', e => {
  //     // let len = $(e.target)
  //     //   .parents(me.opts.clazz)
  //     //   .find('.js_dm_share').length;

  //     // if (len == 0 && me.$share.css('display') === 'block') {
  //       me.$share.hide();
  //     // }
  //   });
  // } else {
    document.addEventListener('click', e => {
      if (me.$element && me.$element[0] && (me.$element[0].contains(e.target) || e.target == me.$element[0])) {
      } else {
        me.$share.hide();
      }
    }, false);
    // $doc.on('click', e => {
    //   let len = $(e.target)
    //     .parents(me.opts.clazz)
    //     .find('.js_dm_share').length;

    //   if (len == 0 && me.$share.css('display') === 'block') {
    //     me.$share.hide();
    //   }
    // });
  // }
  // let scrollEmitter = $.makeLazy(
  //   function() {
  //     if (me.$share.css('display') == 'block') {
  //       lastDom && lastDom.showBox();
  //     }
  //   },
  //   500,
  //   100
  // );
  // $(win).on('scroll', scrollEmitter);
};

MyShare.prototype.drawQRCode = function() {
  let {
    $share,
    opts: { pageLink, contentType, contentId }
  } = this;

  let href = pageLink || document.URL;
  if (href.indexOf('?') != -1) {
    href += '&';
  } else {
    href += '?';
  }

  const urlParams = {
    utm_source: 'wechat',
    utm_medium: 'social',
    utm_campaign: 'pc_wechat_share',
    utm_content: getUtmContent(contentType, contentId),
    s: 'pc_wechat_share'
  };

  href += urlParam(urlParams);
  
  /*
  // 分享短链接被微信拦截，暂时用原始链接，并且降低容错率
  $.ajax({
    url: '/www/public/tiny-url',
    type: 'POST',
    data: {
      url: href
    },
    dataType: 'json'
  }).done(function(res) {
    if (res.status === 1) {
      $share.find('.j-wechat-qrcode').html('').qrcode({
        text: res.data.url,
        width: 300,
        height: 300
      });
    }
  });
  */
  $share.find('.j-wechat-qrcode').html('').qrcode({
    text: href,
    width: 300,
    height: 300,
    correctLevel: 0, // 二维码容错率设置为最低，默认是2
  });
};

MyShare.prototype.copyLinkShare = function() {
  let {
    $share,
    opts: { pageLink, contentType, contentId }
  } = this;

  let href = pageLink || document.URL;
  if (href.indexOf('?') != -1) {
    href += '&';
  } else {
    href += '?';
  }
  var urlParams = {
    utm_source: 'copylink',
    utm_medium: 'social',
    utm_campaign: 'pc_copylink_share',
    utm_content: getUtmContent(contentType, contentId),
    s: 'pc_copylink_share'
  };
  if (href.indexOf('?') != -1) {
    href += '&';
  } else {
    href += '?';
  }
  href += urlParam(urlParams);
  $share.find('.share-copy-input').val(href);
}

function weiboShare(data) {
  var url = data.pageLink || document.URL;
  var urlParams = {
    utm_source: 'weibo',
    utm_medium: 'social',
    utm_campaign: 'pc_weibo_share',
    utm_content: getUtmContent(data.contentType, data.contentId),
    s: 'pc_weibo_share'
  };
  if (url.indexOf('?') != -1) {
    url += '&';
  } else {
    url += '?';
  }
  url += urlParam(urlParams);

  var params = {
    title: data.title,
    url: url,
    appkey: data.weiboParams.appkey,
    pic: data.imgUrl,
    ralateUid: data.weiboParams.ralateUid
  };

  var fwdurl = 'http://service.weibo.com/share/share.php?' + urlParam(params);

  window.open(fwdurl, '_blank', 'scrollbars=no,width=600,height=450,left=75,top=20,status=no,resizable=yes');
}

function fbShare(data) {
  var url = data.pageLink || document.URL;
  var urlParams = {
    utm_source: 'facebook',
    utm_medium: 'social',
    utm_campaign: 'pc_facebook_share',
    utm_content: getUtmContent(data.contentType, data.contentId),
    s: 'pc_facebook_share'
  };
  if (url.indexOf('?') != -1) {
    url += '&';
  } else {
    url += '?';
  }
  url += urlParam(urlParams);
  let appId = FB_APP_ID;
  if (window.site && site.share && site.share.facebookId) {
    appId = site.share.facebookId;
  }
  var params = {
    app_id: appId,
    link: url,
    redirect_uri: url,
    picture: data.imgUrl
  };

  var fwdurl = 'https://www.facebook.com/dialog/feed?' + urlParam(params);
  window.open(fwdurl, '_blank', 'scrollbars=no,width=600,height=450,left=75,top=20,status=no,resizable=yes');
}

function xShare (data) {
  let { title, pageLink, contentType, contentId } = data;
  let href = pageLink || getOgMetaContent('url') || document.URL;
  href = href.replace('.docker.dealmoon.', '.dealmoon.');
  href = href.replace(/\.at[12]\.dealmoon\.net/, '.dealmoon.com');
  if (href.indexOf('?') != -1) {
    href += '&';
  } else {
    href += '?';
  }
  var urlParams = {
    utm_source: 'X',
    utm_medium: 'social',
    utm_campaign: 'pc_X_share',
    utm_content: getUtmContent(contentType, contentId),
    s: 'pc_X_share',
  };
  if (href.indexOf('?') != -1) {
    href += '&';
  } else {
    href += '?';
  }
  href += urlParam(urlParams);
  if (!title) {
    title = getOgMetaContent('title');
  }
  if (!title) {
    title = document.title;
  }
  let url = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(title) + '&url=' + encodeURIComponent(href);
  window.open(url, '_blank');
}

function instagramShare (data) {
  
}
