/**
 * Created by user on 2017/5/22.
 */

import dimensions from './dimensions';
import ga4 from './ga4';
import prevpage from './prevpage';
import { cookie } from 'dm-utils-ts';
import { dmEventDelegate } from 'dm-dom';

var CaseInsensitiveDimensions = {};

let inFrame = false;
try {
  inFrame = window.top !== window;
} catch (err) {
  inFrame = true;
}
for(var key in dimensions) {
  CaseInsensitiveDimensions[key.toLowerCase()] = dimensions[key];
  CaseInsensitiveDimensions[key] = dimensions[key];
}
var gastatistics = {
  init: function() {
    // 被 iframe 等内嵌的页面不发送埋点
    if (inFrame) return;
    this.action.init();
    this.event.init();
  },
  parm: {
    /**
     * html event属性格式 gatrk="eventCategory_eventAction_eventLabel_dimension2_dimension6_dimension8_dimension7_ScreenName_dimension11_dimension3_dimension13"
     * html pagview 属性格式 gatrk="dimension2_title_dimension5_dimension3"
     * dimension2  DealId
     * dimension3  CategoryID
     * dimension4  BannerID
     * dimension5  value
     * dimension6  dpID
     * dimension7 MoonShowPostID
     * dimension8 StoreName
     * dimension9 dpID
     * dimension10 cdPage
     * dimension11 Tag
     * dimension13 guideID
     * dimension21 functionID
     * ScreenName ScreenName
     * */
    eventsendparm: [
      "eventCategory",
      "eventAction",
      "eventLabel",
      "dimension2",
      "dimension6",
      "dimension8",
      "dimension7",
      "ScreenName",
      "dimension11",
      "dimension3",
      "dimension13",
      "dimension5",
      "dimension4",
      "dimension9",
      "dimension21",
      "dimension28", // GuideCategoryID
      "dimension17", // BusinessUnit, 默认dm
      "dimension19", // domainId(guide|deal|....)
      "dimension25", // title
      "dimension22", // Language
    ],
    pageviewsendparm: ["dimension2 ", "title", "dimension5 ", "dimension3", "dimension10"],
    screensendParm: ["appName", "screenName"],
    /**
     * 导航栏ga 统计 转换
     * $ 替换符（CN EN）
     */
    // pcNavAction: {
    //   home: "HomepageTopTab-$-Home",
    //   local: "HomepageTopTab-$-Local",
    //   clickrank: "HomepageTopTab-$-MostClickedDeals",
    //   creditcard: "HomepageTopTab-$-CreditCards",
    //   coupon: "HomepageTopTab-$-Coupons",
    //   stores: "HomepageTopTab-$-OnlineStores",
    //   forum: "HomepageTopTab-$-Moonbbs",
    //   guide: "HomepageTopTab-$-Guide",
    //   post: "HomepageTopTab-$-Moonshow",
    //   zhongce: "HomepageTopTab-$-Zhongce",
    //   integral: "HomepageTopTab-$-Rewardmall",
    //   baoliao: "HomepageTopTab-$-Baoliao",
    //   special: "HomepageTopTab-$-HolidayTab",
    //   haitao: "HomepageTopTab-$-Haitao"
    // },
    rightNavAction: {
      bbs: "HomepageRightNav-$-BBS",
      creditcard: "HomepageRightNav-$-CreditCards",
      followwechat: "HomepageRightNav-$-FollowWechat",
      contactus: "HomepageRightNav-$-ContactUs",
      hottest: "HomepageRightNav-$-Hottest",
      beauty: "HomepageRightNav-$-Beauty",
      secondmarket: "HomepageRightNav-$-SecondMarket",
      mobile: "HomepageRightNav-$-Mobile"
    },
    lang: "CN",
    isTest: false
  },
  event: {
    init: function() {
      //检查是否是测试环境
      this.checkIsTest();
      //模板绑定事件
      this.ga_event_statistics();
      // 编辑器中内容点击事件（文字  图片）
      this.ga_event_statistics_content();
      //guide 单品点击事件
      this.ga_event_statistics_content_guide();
      //导航点击事件
      // this.ga_event_statistics_nav_list();
      //检查导航是否有缓存未提交,如果有则提交 并清除，如果没有则略过
      this.ga_event_check_nav_list();
      //screen
      this.ga_screen_statistics();
      // 右侧导航点击事件
      this.ga_event_statistics_right_nav();
      // 检查右侧导航是否有缓存未提交,如果有则提交 并清除，如果没有则略过
      this.ga_event_check_right_nav();
    },
    ga_event_statistics: () => {
      dmEventDelegate(document, 'click', ".event_ga_statistics", e => {
        const target = e.target.closest('.event_ga_statistics');
        gastatistics.action.ga_event_statistics(target.getAttribute('gatrk'), {
          pgn: target.getAttribute('gapgn')
        });
      });
    },
    ga_event_statistics_content: () => {
      dmEventDelegate(
        document,
        'click',
        '.ga_event_statistics_content a',
        e => {
          gastatistics.action.ga_event_statistics_content(e.target.closest('a'));
        }
      );
    },
    
    /**
     * dm_external_goods已经没用了
     * @description 长文章详情外链商品点击埋点
     * @deprecated
     * 重构后没测试，因为找不到这样的长文章了
     */
    ga_event_statistics_content_guide: () => {
      dmEventDelegate(
        document,
        "click",
        ".ga_event_statistics_content_guide .dm_external_goods",
        e => {
          gastatistics.action.ga_event_statistics_content_guide(e.target.closest('.dm_external_goods'));
        }
      );
    },
    // ga_event_statistics_nav_list: () => {
    //   dmEventDelegate(
    //     document,
    //     "click",
    //     ".ga_event_statistics_nav_list",
    //     e => {
    //       gastatistics.action.ga_event_statistics_nav_list(e.target.closest('.ga_event_statistics_nav_list').getAttribute('gatrk'));
    //     }
    //   );
    // },
    ga_event_statistics_right_nav: () => {
      dmEventDelegate(
        document,
        'click',
        '.ga_event_statistics_right_nav',
        e => {
          gastatistics.action.ga_event_statistics_right_nav(e.target.closest('.ga_event_statistics_right_nav').getAttribute('gatrk'));
        }
      );
    },
    ga_event_check_nav_list: () => {
      gastatistics.action.ga_event_check_nav_list();
    },
    ga_event_check_right_nav: () => {
      gastatistics.action.ga_event_check_right_nav();
    },
    checkIsTest: () => {
      var testMatches = window.location.href.match(
        /[.\/]+((it|st|at)\w*.)dealmoon.net/
      );
      if (testMatches && testMatches[1]) {
        gastatistics.parm.isTest = true;
      }
    },
    /**
     * 页面可能已停用, ELK查不到访问记录
     * @referrer http://www.dealmoon.com/mall-order
     */
    ga_screen_statistics: function() {
      dmEventDelegate(document, 'click', '.screen_ga_statistics', e => {
        gastatistics.action.ga_screen_statistics(e.target.closest('.screen_ga_statistics').getAttribute('gatrk'));
      });
    }
  },
  action: {
    _gaList: [],
    // 统一发起统计，有可能GA还未加载完，放入队列
    ga: function() {
      // 被 iframe 等内嵌的页面不发送埋点
      if (inFrame) {
        return;
      }
      if (arguments.length) {
        var params = Array.prototype.slice.call(arguments);
        if (params[0] == 'send') {
          if (params[1] && params[1].hitType === 'pageview') {
            params[1].dimension12 = prevpage(params[1].dimension10 || params[1].page);
          }
        }
        ga4.send.apply(ga4, params);
        this._gaList.push(params);
      }
      if (typeof window.ga === "function") {
        var args = [];
        while ((args = this._gaList.shift())) {
          window.ga.apply(window, args);
        }
      }
    },

    ga_event_statistics: function(parm, options) {
      var parmkey = gastatistics.parm.eventsendparm;
      var newparm = this.set_ga_post_parm(parm, parmkey);
      // 去掉所有 DealBuy 的事件
      if (newparm && newparm.eventCategory === 'DealBuy') {
        if (gastatistics.parm.isTest) {
          console.log('ignore events: eventCategory=DealBuy', newparm);
        }
        return;
      }
      if (gastatistics.parm.isTest) {
        console.log("newparm", newparm);
      }
      if (newparm) {
        newparm["hitType"] = "event";
        if (!newparm["eventCategory"]) {
          newparm["eventCategory"] = "WEB";
        }
        if (!newparm["eventLabel"]) {
          newparm["eventLabel"] = 'yh:{userid}|pf:{platform}|pgn:{pagename}';
        }
        if (newparm["eventLabel"]) {
          let userId = (cookie.readCookie('usertoken') || "").split("|").shift() || 0;
          let pf = window.interfacedata && interfacedata.platform && typeof interfacedata.platform === 'string' ? interfacedata.platform.toLowerCase(): 'pc';
          let pgn = options && options.pgn ? options.pgn : window.pageType;
          newparm["eventLabel"] = newparm["eventLabel"].replace(/yh\:{[^}]*}/g, 'yh:' + userId);
          newparm["eventLabel"] = newparm["eventLabel"].replace(/pf\:{[^}]*}/g, 'pf:' + pf);
          newparm["eventLabel"] = newparm["eventLabel"].replace(/pgn\:{[^}]*}/g, pgn ? ('pgn:' + pgn) : '');
          // newparm["eventLabel"] = newparm["eventLabel"].replace(/pgn\:[^|]+/g, ($0) => {
          //   return $0.replace(/[\-_\s\.]/g, '')
          // });
          if (!/yh:/.test(newparm["eventLabel"])) {
            newparm["eventLabel"] += `|yh:${userId}`;
          }
          if (!/pf:/.test(newparm["eventLabel"])) {
            newparm["eventLabel"] += `|pf:${pf}`;
          }
          if (!/pgn:/.test(newparm["eventLabel"])) {
            newparm["eventLabel"] += `|pgn:${pgn}`;
          }
        }
        //如果Refer存在就加入Refer（dimension12-PrevPage）
        document.referrer && (newparm["dimension12"] = document.referrer);
        this.ga("send", newparm);
      }
    },
    ga_event_statistics_content: function(obj) {
      if (obj.href && obj.href.indexOf("exe") != -1) {
        let parm = obj.closest('.ga_event_statistics_content').getAttribute('gatrk');
        if (parm) {
          var newdata = parm.split("_");
          newdata[1] = obj.querySelectorAll("img").length >= 1 ? "Photos" : "Content";
          this.ga_event_statistics(newdata.join("_"));
        }
      }
    },
    /**
     * 长文章详情外链商品点击埋点
     * @param {*} obj 
     * @deprecated 
     */
    ga_event_statistics_content_guide: obj => {
      const parm = obj.closest(".ga_event_statistics_content_guide").getAttribute("gatrk");
      if (parm) {
        var newdata = parm.split("_");
        var attributeNode = obj;
        newdata[2] = attributeNode.getAttribute("data-pro_title");
        newdata[4] =
          attributeNode.getAttribute("data-pro_id") +
          "-" +
          attributeNode.getAttribute("data-pro_title");
        this.ga_event_statistics(newdata.join("_"));
      }
    },
    ga_screen_statistics: function(parm) {
      var parmkey = gastatistics.parm.screensendParm;
      var newparm = this.set_ga_post_parm(parm, parmkey);
      if (newparm) {
        newparm["hitType"] = "screenview";
        this.ga("send", newparm);
      }
    },

    ga_pageview_statics: function(parm, path) {
      var parmkey = gastatistics.parm.pageviewsendparm;
      var newparm = this.set_ga_post_parm(parm, parmkey);
      if (newparm) {
        newparm["hitType"] = "pageview";
        newparm["page"] = path;
        this.ga("send", newparm);
      } else {
        newparm = "pageview";
        this.ga("send", newparm, path);
      }
    },
    get_ga_post_parm: function(data, parmkey) {
      if (!data) {
        console.error("ga post 参数错误");
        return false;
      }
      var dimensionData = {};
      for(var key in data) {
        if (key && typeof key === 'string') {
          var dimensionKey = CaseInsensitiveDimensions[key.toLowerCase()];
          if (dimensionKey) {
            dimensionData[dimensionKey] = data[key];
          } else {
            dimensionData[key] = data[key];
          }
        }
      }
      return parmkey.map(function (key) {
        var value = dimensionData[key];
        return value || value == 0 ? (value + '') : '';
      }).join('_');
    },
    set_ga_post_parm: function(parm, parmkey) {
      if (!parm) {
        console.error("ga post 参数错误");
        return false;
      }
      var newparm = {};
      var parmisempty = true;
      if (typeof parm === 'string') {
        var newdata = parm.split("_");
        for (var i = 0, len = newdata.length; i < len; i++) {
          if (newdata[i].trim().length) {
            newparm[parmkey[i]] = newdata[i];
            parmisempty = false;
          }
        }
      } else if (parm && typeof parm === 'object') {
        var parmisempty = true;
        for (var key in parm) {
          var value = parm[key];
          if (value && typeof value === 'string') {
            value = value.trim();
            if (value) {
              let dimensionKey = dimensions[key];
              if (dimensionKey) {
                newparm[dimensionKey] = value;
                parmisempty = false;
              } else if (/^(dimension\d+)|(eventCategory)|(eventAction)|(eventLabel)$/.test(key)) {
                newparm[key] = value;
                parmisempty = false;
              }
            }
          }
        }
      }
      return parmisempty == false ? newparm : "";
    },

    // new ga sen pageview function
    ga_new_send_pageview_stat: function(customParam = {}) {
      var param = {
        hitType: "pageview",
        dimension17: "dm",
        dimension22: window.dmLang,
        dimension10: customParam.page
      };

      if (customParam) {
        Object.assign(param, customParam);
        delete param.page;
      }
      this.ga("send", param);
    },

    // ga_event_statistics_nav_list: function(parm) {
    //   if (!parm || typeof parm.split !== 'function') return;
    //   var newparm = parm.split("_");
    //   var lang = gastatistics.parm.lang;
    //   var eventAction = gastatistics.parm.pcNavAction[newparm[0]];
    //   if (!eventAction) return;
    //   var eventLangAction = eventAction.replace("$", lang);
    //   var eventsendparm = "HomepageTopTab-CN_" + eventLangAction;
    //   //如果是新页面打开 则统计在当前页面发送，如果是在当前页面打开则 先记录需提交的数据，然后再新页面打开的时候再提交
    //   if (newparm[1] == "new") {
    //     this.ga_event_statistics(eventsendparm);
    //   } else {
    //     var datas = {
    //       eventsendparm: eventsendparm
    //     };
    //     try {
    //       localStorage &&
    //         localStorage.setItem(
    //           "ga_event_statistics_nav",
    //           JSON.stringify(datas)
    //         );
    //     } catch (e) {
    //       console.error(e);
    //     }
    //   }
    // },
    ga_event_statistics_right_nav: function(parm) {
      if (!parm || typeof parm.split !== 'function') return;
      var newparm = parm.split("_");
      var lang = gastatistics.parm.lang;
      var eventAction = gastatistics.parm.rightNavAction[newparm[0]];
      if (!eventAction) return;
      var eventLangAction = eventAction.replace("$", lang);
      var eventsendparm = "HomepageRightNav-CN_" + eventLangAction;
      //如果是新页面打开 则统计在当前页面发送，如果是在当前页面打开则 先记录需提交的数据，然后再新页面打开的时候再提交
      if (newparm[1] == "new") {
        this.ga_event_statistics(eventsendparm);
      } else {
        var datas = {
          eventsendparm: eventsendparm
        };
        try {
          localStorage &&
            localStorage.setItem(
              "ga_event_statistics_right_nav",
              JSON.stringify(datas)
            );
        } catch (e) {
          console.error(e);
        }
      }
    },
    ga_event_check_right_nav: function() {
      try {
        var ga_event_statistics_right_nav =
          localStorage && localStorage.getItem("ga_event_statistics_right_nav");
        var eventsendparm = JSON.parse(ga_event_statistics_right_nav);
        if (eventsendparm) {
          localStorage.removeItem("ga_event_statistics_right_nav");
          this.ga_event_statistics(eventsendparm.eventsendparm);
        }
      } catch (e) {
        console.error(e);
      }
    },
    ga_event_check_nav_list: function() {
      try {
        var ga_event_statistics_nav =
          localStorage && localStorage.getItem("ga_event_statistics_nav");
        var eventsendparm = JSON.parse(ga_event_statistics_nav);
        if (eventsendparm) {
          localStorage.removeItem("ga_event_statistics_nav");
          this.ga_event_statistics(eventsendparm.eventsendparm);
        }
      } catch (e) {
        console.error(e);
      }
    },

    init: function() {
      (function(i, s, o, g, r, a, m) {
        i["GoogleAnalyticsObject"] = r;
        if (i[r]) return;
        (i[r] =
          i[r] ||
          function() {
            (i[r].q = i[r].q || []).push(arguments);
          }),
          (i[r].l = 1 * new Date());
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(
        window,
        document,
        "script",
        "https://www.google-analytics.com/analytics.js",
        "ga"
      );

      ga("create", window.gaCode, "auto");
      this.ga();

      if (document.URL.indexOf("utm_source") <= 0) {
        var utm_source = cookie.readCookie("utm_source");
        var utm_campaign = cookie.readCookie("utm_campaign");
        var utm_medium = cookie.readCookie("utm_medium");
        var utm_content = cookie.readCookie("utm_content");
        var utm_term = cookie.readCookie("utm_term");
        var userId = (cookie.readCookie("usertoken") || "").split("|").shift();
        if (utm_source) {
          ga4("set", "campaignSource", utm_source);
        }
        if (utm_campaign) {
          ga4("set", "campaignName", utm_campaign);
        }
        if (utm_medium) {
          ga4("set", "campaignMedium", utm_medium);
        }
        if (utm_content) {
          ga4("set", "campaignContent", utm_content);
        }
        if (utm_term) {
          ga4("set", "campaignKeyword", utm_term);
        }
        if (userId) {
          ga4("set", "userId", userId);
        }
      }
    }
  }
};

// 页面加载完毕了才加载，防止浏览器阻塞
window.dmLazyCall(function() {
  gastatistics.init();
});

window.gastatistics = gastatistics;
export default gastatistics;
