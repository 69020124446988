const dimensions = {
  UDID: 'dimension1', // 设备ID
  DealID: 'dimension2', // 折扣id
  CategoryID: 'dimension3', // 分类
  BannerID: 'dimension4', // bannerid
  Value: 'dimension5', // 很多场景都有在用value来记录
  SKUID: 'dimension6', // 单品id
  MoonShowPostID: 'dimension7', // 笔记id
  MoonShowID: 'dimension7', // 笔记id
  StoreName: 'dimension8', // 商家名
  dpID: 'dimension9', // 单品id
  cdPage: 'dimension10', // PC/WAP专用，用来记录page name
  Tag: 'dimension11', //
  PrevPage: 'dimension12', // 上一页
  GuideID: 'dimension13', // 长文章id
  EditorID: 'dimension14', // editor id
  ChannelID: 'dimension15', // 编辑组
  UID: 'dimension16', // 用户ID
  BusinessUnit: 'dimension17', // 区分local和dm
  LocalMetroCity: 'dimension18', // Local大区
  DomainID: 'dimension19', // 区分各业务线
  subCategoryID: 'dimension20', // 二级分类
  functionID: 'dimension21', // 用于记录其他业务的id，比如众测，爆料等的id
  Language: 'dimension22', // 语言
  DistanceToStore: 'dimension23', // 用户离店距离
  APPID: 'dimension24', //
  Title: 'dimension25', // 标题
  BizStickerID: 'dimension26', // 商家内容贴片
  BizLocationID: 'dimension27', // 商家位置贴片
  GuideCategoryID: 'dimension28', // 攻略分类
  Type: 'dimension29', // 类型
  MoonshowCatID: 'dimension30' // 笔记分类
};

export default dimensions;
