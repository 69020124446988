/**
 * add liming
 *
 * 使用方法:
 *
 *  dmWinpop.alert(str,titleStr,okActionFun,type) str 为内容，titleStr 为弹出框标题 okActionFun为回调，可不设置  type可设置为html
 *
 *  confirm : 需要传递okActionFun cancelActionFun
 *  dmWinpop.confirm('是否删除',function(){ 确定回调}, function(){取消回调});
 *
 *  prompt('infoStr' 'beforeStr' ,okActionFun, cancelActionFun,otherOptions);
 *  dmWinpop.prompt('输入提示', '之前内容',function(){ 输入回调}, function(){取消回调});
 *  beforeStr可以不传 第二个参数直接传okActionFun
 *
 *   * otherOptions: {
 *  tipText: 第一行提示文件
 *  cannotNull :输入框内容不能为空
 *  placeholder: 输入框提示文本
 * }
 *
 *
 *  dmWinpop.notice(message,type,time)
 *  左下角弹出提示框
 *  message:提示内容，最多不超过10个字
 *  type类型：目前支持success,error,warn 默认为success
 *  time: 多长时间收起，默认为2s
 *
 * @type {{ovl: string, alert: string, confirm: string}}
 */

import './index.less';

var allHtmls = {
  ovl: '<div class="J_WinpopMask winpop-mask" id="J_WinpopMask"></div>' +
    '<div class="J_WinpopBox winpop-box" id="J_WinpopBox">' +
    '<div class="J_WinClose ee-icon-close"></div>' +
    '<div class="J_WinTitle">提示</div>' +
    '<div class="J_WinpopIcon"><div class="J-Winpop-icon-image"></div></div>' +
    '<div class="J_WinpopContent">' +
    '<div class="J_WinpopMain winpop-main"></div>' +
    '<div class="J_WinpopBtns winpop-btns"></div>' +
    '</div>' +
    '</div>',

  alert: '<input type="button" class="J_AltBtn pop-btn confirm-true" value="确&nbsp;&nbsp;定">',
  confirm: '<input type="button" class="J_CfmFalse pop-btn confirm-false" value="取&nbsp;&nbsp;消">' +
    '<input type="button" class="J_CfmTrue pop-btn confirm-true" value="确&nbsp;&nbsp;定">',
  prompt: '<input type="button" class="J_PromptFalse pop-btn confirm-false" value="取&nbsp;&nbsp;消">' +
    '<input type="button" class="J_PromptTrue pop-btn confirm-true" value="确&nbsp;&nbsp;定">'
};

function Winpop() {
  //全局缓存
  var config = {};
  this.get = function (n) {
    return config[n];
  };

  this.set = function (n, v) {
    config[n] = v;
  };
}

Winpop.prototype = {
  isShow: false, //弹出框是否显示
  isWap: false,  //是否移动端
  //初始化
  init: function () {
    if (jQuery("#J_WinpopBox").length == 0) {
      this.createDom();
      this.bindEvent();
    }

    jQuery.fn.slideLeftHide = function (speed, callback) {
      this.animate({
        width: "hide",
        paddingLeft: "hide",
        paddingRight: "hide",
        marginLeft: "hide",
        marginRight: "hide"
      }, speed, callback);
    };

    jQuery.fn.slideLeftShow = function (speed, callback) {
      this.animate({
        width: "show",
        paddingLeft: "show",
        paddingRight: "show",
        marginLeft: "show",
        marginRight: "show"
      }, speed, callback);
    }

    jQuery.fn.slideTopHide = function (speed, callback) {
      this.animate({
        'top': "-100%"
      }, speed, callback);
    };

    jQuery.fn.slideTopShow = function (speed, callback) {
      this.animate({
        'top': "0%"
      }, speed, callback);
    }

    if(this.isWap) {
      this.get("ovl").addClass('wap');
    }
  },

  triggerClassClick: function (element, className, cb) {
    element.addEventListener('click', function (e) {
      if (e.target.className.indexOf(className) != -1) {
        cb && cb(e);
      }
    })
  },

  createDom: function () {
    var body = jQuery("body"),
      ovl = jQuery("#J_WinpopBox");

    if (ovl.length === 0) {
      body.append(allHtmls.ovl);
    }

    this.set("ovl", jQuery("#J_WinpopBox"));
    this.set("mask", jQuery("#J_WinpopMask"));
  },

  bindEvent: function () {
    var _this = this,
      ovl = _this.get("ovl"),
      mask = _this.get("mask");
    _this.triggerClassClick(ovl[0], 'J_AltBtn', function (e) {
      var cb = _this.get("okActionFun");
      _this.hide();
      cb && cb();
    });

    _this.triggerClassClick(ovl[0], 'J_CfmTrue', function (e) {
      var cb = _this.get("okActionFun");
      _this.hide();
      cb && cb();
    });

    _this.triggerClassClick(ovl[0], 'J_CfmFalse', function (e) {
      var cb = _this.get("cancelActionFun");
      _this.hide();
      cb && cb();
    });

    _this.triggerClassClick(ovl[0], 'J_PromptFalse', function (e) {
      var cb = _this.get("cancelActionFun");
      _this.hide();
      cb && cb();
    });

    _this.triggerClassClick(ovl[0], 'J_PromptTrue', function (e) {
      var otherOptions = _this.get("otherOptions"),
        value = _this.get("promptValObj").val();

      value = (value || '').trim();
      if (otherOptions && otherOptions.cannotNull && !value) {
        _this.get("ovl").find('.prompt-error-tip').html(otherOptions.nullTips).show();
      } else {
        var cb = _this.get("okActionFun");
        _this.hide();
        cb && cb(_this.get("promptValObj").val());
      }
    });

    _this.triggerClassClick(ovl[0], 'J_WinClose', function (e) {
      _this.hide();
    });

    _this.triggerClassClick(ovl[0], 'J_promptValue', function (e) {
      $(this).parent().find('.prompt-error-tip').hide();
    });

    document.getElementById('J_WinpopBox').addEventListener('keydown', function (e) {
      if (_this.isShow) {
        var kc = e.keyCode,
          cb = _this.get("okActionFun");

        if (kc === 27) {
          //esc隐藏
          _this.hide();
        } else if (kc === 13) {
          //enter 默认为true
          _this.hide();
          if (_this.get("type") === "confirm") {
            cb && cb();
          } else if (_this.get("type") === "prompt") {
            cb && cb(_this.get("promptValObj").val());
          }
        }
      }
    });
  },

  /**
   * @param str 弹出框主要显示内容
   * @param titleStr 弹出框标题
   * @param okActionFun 确定回调
   * @param type 类型  默认为text 可设置为html
   * @param options 其它配置参数
   */
  alert: function (str, titleStr, okActionFun, type, options) {
    this.init();
    var ovl = this.get("ovl");
    
    str = typeof str === 'string' ? str : str.toString(),
    this.set("type", "alert");
    if (typeof okActionFun === 'string') {
      type = okActionFun;
    }
    var htmlStr = type == 'html' ? str : $('<div>').text(str).html().replace(/\n/g, '<br/>');
    ovl.find(".J_WinpopMain").html(htmlStr);
    // ovl.find(".winpop-btns").css({
    //   'text-align': 'center'
    // });
    ovl.find(".J_WinpopBtns").html(allHtmls.alert);
    if (titleStr) {
      ovl.find(".J_WinTitle").html(titleStr);
    } else {
      ovl.find(".J_WinTitle").remove();
    }
    if (options && options.okText) {
      ovl.find(".confirm-true").val(options.okText);
    }
    this.set("okActionFun", ((okActionFun && typeof okActionFun == 'function') ? okActionFun : function () {}));
    this.show();
  },

  /**
   * @param str 确认框文本信息
   * @param okActionFun 确认框 "确定" 回调函数
   * @param cancelActionFun 确认框 "取消" 回调函数
   * @param type 类型  默认为text 可设置为html
   * @param options 其它配置参数
   */
  confirm: function (str, okActionFun, cancelActionFun, options) {
    this.init();
    var str = typeof str === 'string' ? str : str.toString(),
      ovl = this.get("ovl");
    this.set("type", "confirm");
    ovl.find(".J_WinpopBtns").html(allHtmls.confirm);

    if (typeof cancelActionFun === 'string') {
      type = cancelActionFun;
    }

    options = options || {};
    if (options.okText) {
      ovl.find(".J_CfmTrue").val(options.okText);
    }
    if (options.cancelText) {
      ovl.find(".J_CfmFalse").val(options.cancelText);
    }
    if (options.okClass) {
      ovl.find(".J_CfmTrue").addClass(options.okClass);
    }
    if (options.cancelClass) {
      ovl.find(".J_CfmFalse").addClass(options.cancelClass);
    }
    if (options.popClass) {
      ovl.addClass(options.popClass);
    }
    if (options.titleStr) {
      ovl.find(".J_WinTitle").html(options.titleStr);
    } else {
      ovl.find(".J_WinTitle").remove();
    }

    ovl.find(".winpop-btns").css({
      'text-align': 'right'
    });
    var htmlStr = options.type == 'html' ? str : $('<div>').text(str).html().replace(/\n/g, '<br/>');
    ovl.find(".J_WinpopMain").html(htmlStr);
    this.set("okActionFun", ((okActionFun && typeof okActionFun == 'function') ? okActionFun : function () {}));
    this.set("cancelActionFun", ((cancelActionFun && typeof cancelActionFun == 'function') ? cancelActionFun : function () {}));
    this.show();
  },

  /**
   * @param str 输入框提示文本
   * @param beforeStr 输入框之前内容
   * @param okActionFun 输入框 "确定" 回调函数
   * @param cancelActionFun 输入框 "取消" 回调函数
   *
   * otherOptions: {
   *  tipText: 第一行提示文件
   *  cannotNull :输入框内容不能为空
   *  placeholder: 输入框提示文本
   * }
   */
  prompt: function (str, beforeStr, okActionFun, cancelActionFun, otherOptions) {
    this.init();
    var str = typeof str === 'string' ? str : str.toString(),
      ovl = this.get("ovl"),
      mainHtmls = [],
      placeholder;

    if (typeof beforeStr === 'function') {
      otherOptions = cancelActionFun || {};
      cancelActionFun = okActionFun;
      okActionFun = beforeStr;
      beforeStr = '';
    }

    otherOptions = otherOptions || {};
    this.set("type", "prompt");
    ovl.find(".J_WinTitle").html(str);
    if (otherOptions.tipText) {
      mainHtmls.push('<p class="tip-text">' + otherOptions.tipText + '</p>');
    }
    placeholder = otherOptions.placeholder || '';
    mainHtmls.push('<input class="J_promptValue" placeholder="' + placeholder + '" value=' + beforeStr + '><br><span class="prompt-error-tip"></span>');

    ovl.find(".J_WinpopMain").html(mainHtmls.join('')).addClass('J_WinpopMainPrompt');
    ovl.find(".J_WinpopBtns").html(allHtmls.prompt);
    ovl.find(".winpop-btns").css({
      'text-align': 'right'
    });
    this.set("okActionFun", (okActionFun || function () {}));
    this.set("cancelActionFun", (cancelActionFun || function () {}));
    this.set("promptValObj", (ovl.find(".J_promptValue")));
    this.set("otherOptions", otherOptions);
    setTimeout(function () {
      ovl.find('.J_promptValue').focus();
    }, 500);
    this.show();
  },

  show: function () {
    var self = this;
    setTimeout(function () {
      self.get("ovl").show();
      self.get("ovl").find('.confirm-true').trigger("focus"); //确认按纽获得焦点
      self.get("mask").show();
      self.get("ovl").focus();
      self.isShow = true;
    }, 200);
  },

  hide: function () {
    var ovl = this.get("ovl"),
      mask = this.get("mask");
    this.isShow = false;
    if (!ovl) return;
    ovl.find(".J_WinpopMain").html("").removeClass('J_WinpopMainPrompt');
    ovl.find(".J_WinpopBtns").html("");
    ovl.find(".J_WinTitle").html("提示");
    ovl.hide();
    mask && mask.hide();
  },

  destory: function () {
    this.get("ovl").remove();
    this.get("mask").remove();
    delete window.alert;
    delete window.confirm;
    delete window.prompt;
  },

  /**
   * 左上角提示框
   * @param message 提示内容，最多不超过10个字
   * @param type 目前支持success,error,warn 默认为success
   * @param time 多长时间收起，默认为2s
   */
  notice: function (message, type, time) {
    if (type && typeof type === 'object') {
      var options = type;
      type = options.type;
      time = options.time;
    } else {
      var options = {
        type: type,
        time: time
      };
    }

    this.init();
    var noticeId = 'ee-winpop-notice-id-' + new Date().getTime() + '-' + parseInt(Math.random() * 10000);
    var isSlideFromTop = options.slideFrom === 'top';

    function _slideShow() {
      isSlideFromTop ? $('#' + noticeId).slideTopShow(200) : $('#' + noticeId).slideLeftShow(200);
    };

    function _slideHide(noticeId, isSlideFromTop) {
      return function () {
        var jObj = $('#' + noticeId);
        jObj[isSlideFromTop ? 'slideTopHide' : 'slideLeftHide'](200, function (params) {
          jObj.remove();
        });
      }
    };

    var container = options.container || $("body");
    if (isSlideFromTop) {
      if (!container) {
        alert('未实现')
      }
      var classType = type === 'success' ? 'success' : 'error';
      var infoHtml = "<div class='ee-winpop-notice-from-top' id='" + noticeId + "'>";
      infoHtml += "<span class='close ee-icon-cross'></span>";
      infoHtml += "<div class='main-content win-type-" + classType + "'><div class='win-info-type'><div class='icon'></div></div><div class='win-info-content'>";
      infoHtml += "<i class='dm-icon dm-icon-close close'></i>&nbsp;";
      infoHtml += options.contentType == 'html' ? message : message.replace(/\n/g, '<br/>')
      infoHtml += "</div></div>";
      infoHtml += "</div>";
      container.css('position', 'relative');
    } else {
      var classType = type === 'error' ? 'warn' : 'success';
      var infoHtml = "<div class='winpop-warn-info' id='" + noticeId + "'>";
      infoHtml += "<span class='close ee-icon-close'></span>";
      infoHtml += "<div class='main-content win-type-" + classType + "'><div class='win-info-type'><div class='icon ee-icon-border-" + classType + "'></div></div><div class='win-info-content'>";
      infoHtml += options.contentType == 'html' ? message : message.replace(/\n/g, '<br/>')
      infoHtml += "</div></div>";
      infoHtml += "</div>";

      //$('#' + noticeId).find('.main-content').removeClass().addClass('main-content win-type-' + type);
      //$('#' + noticeId).find('.main-content .icon').removeClass().addClass('icon ee-icon-border-' + type);
      //$('#' + noticeId).find('.win-info-content').text(message);
    }
    container.append(infoHtml);
    $('#' + noticeId).on('click', '.close', _slideHide(noticeId, isSlideFromTop));

    _slideShow();
    if (time !== -1) {
      setTimeout(_slideHide(noticeId, isSlideFromTop), time || 2000);
    }
  }
};

//覆盖原有方法();
var winpop = new Winpop();

window.dmWinpop = winpop;

//
// exports.alert = function (str, titleStr, okActionFun, type) {
//     winpop.alert.call(winpop, str, titleStr, okActionFun, type);
// };
// exports.confirm = function (str, okActionFun, cancelActionFun, options) {
//     winpop.confirm.call(winpop, str, okActionFun, cancelActionFun, options);
// };
// exports.prompt = function (str, beforeStr, okActionFun, cancelActionFun, otherOptions) {
//     winpop.prompt.call(winpop, str, beforeStr, okActionFun, cancelActionFun, otherOptions);
// };
//
// exports.notice = function (message, type, time) {
//     winpop.notice.call(winpop, message, type, time);
// };
//
// exports.hide = function () {
//     winpop.hide.call(winpop);
// };
