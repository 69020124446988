// todo remove

var login = {};

login.detail = {

	logMailFocus: function () {
		$("#logMail").addClass("focusborder").trigger('focus');
	},
	hidPass: function () {
		$("#hidPass")
			.on('focus', function () {
				var val = $.trim($(this).val());
				var defaultVal = $(this).attr("placeholder");
				if (val == defaultVal) {
					$(this).hide();
					$("#logPass").show().focus();
				}
			})
			.on('blur', function () {
				var val = $.trim($(this).val());
				if (val == "") {
					var defaultVal = $(this).attr("placeholder");
					$(this).val(defaultVal);
					$(this).hide();
					$("#hidPass").show();
				}
			});
	},

	focusStyle: function (obj) {
		$(obj)
			.on("focus", function () {
				$(this).addClass("focusborder");
			})
			.on("blur", function () {
				$(this).removeClass("focusborder");
			});
	}
};

window.login = login;
export default login;
