/**
 * author: liming
 * 调用方法:
 * 1. 引用jcrop js /css  位置: /assets/lib/cropper/2.3.4/css/cropper.min.css, /assets/lib/cropper/2.3.4/js/cropper.min.js
 * 2. 引用该js文件
 * 3. 使用
 $.jcropImg({
        url: url,  //原图url(不能为空)
        uploadUrl: imageUploadUrl, //上传图片Url (不能为空)
        cropOptions: //自定义的裁剪配置 (可以为空)  具体配置参考jcrop 文档  https://github.com/tapmodo/Jcrop/
        callback: function(url) {
           if(url) {
              //裁剪后的url
           } else {
              //上传失败
           }
        }
     });
 */

(function ($) {

    $.jcropImg = function (options) {

        var $cropWrapper, $cropDom;
        options.cropOptions = options.cropOptions || {};

        var cropOptions = $.extend({
            aspectRatio: 1,
            minCropBoxWidth: 40,
            minCropBoxHeight: 40,
            scalable: false,
            zoomable: false,
            autoCropArea: 1,
            widthSet:375, //浮层的宽度，默认375
            maxHeight:'none' //浮层的高度，默认不设置
        }, options.cropOptions);
        if(!options.url || !options.uploadUrl) {
            alert('参数配置有误!');
            return false;
        }

        /**
         * 判断是否存在界面
         */
        function checkDom() {
            if( !$('body').find('.crop-wrapper').length ) {
                var container = '\
              <div class="crop-wrapper-back" style="z-index:23;opacity: .3;filter: alpha(opacity=30); position: fixed;left: 0;top: 0;width: 100%;height: 100%;background: #000;"></div>\
              <div class="crop-wrapper mce-panel" style="display: none;z-index:24;position: fixed;top: 50%;left: 50%;background: #fff; border-radius: 5px; width:'+cropOptions.widthSet+'px;transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);-moz-transform: translate(-50%, -50%);">\
                  <div id="crop-area"><img class="loading" src="/assets/images/loading-icon.gif"/><img id="crop-img" style="max-height:'+cropOptions.maxHeight+'"></div>\
                  <div class="action-area" style="position: relative;padding: 10px;line-height: 30px;">\
                     <span class="tips" style="color: #999;font-size: 12px;line-height: 14px;margin: 0px;">可拖动选择、调整大小</span>\
                     <div class="action-btn" style=" float: right;">\
                        <button class="btn btn-cancel" style="height: 30px;line-height: 30px;padding: 0px 10px;font-size: 12px;border: none;background: #f2f2f2;border-radius: 2px;color: #666;cursor: pointer;margin-left: 10px;">取消</button>\
                        <button id="save-crop-btn" class="btn btn-default" style="height: 30px;line-height: 30px; background: #df2169;color: #fff;padding: 0px 10px;font-size: 12px;border: none;border-radius: 2px;cursor: pointer;margin-left: 10px;">\
                           <span class="text">确定</span>\
                        </button>\
                     </div>\
                  </div>\
               </div>\
                ';

                $('body').append(container);
            }

            $cropWrapper = $('.crop-wrapper');
        }

        //上传图片blob
        function uploadBlob(blob, callback) {
            var formData = new FormData();
            formData.append('file', blob, "blob.png");
            formData.append("state", "singleDeal");
            $.ajax(options.uploadUrl, {
                type: "POST",
                data: formData,
                processData: false,
                contentType: false,
                success: function (res) {
                    res = JSON.parse(res);
                    if (res && res.status && res.data) {
                        callback && callback(res.data);
                    } else {
                        callback && callback();
                    }
                },
                error: function () {
                    callback && callback();
                }
            });
        }

        //绑定事件
        function bindEvent() {
            //取消裁图
            $cropWrapper.on('click', '.btn-cancel', function () {
                $('.crop-wrapper').remove();
                $('.crop-wrapper-back').remove();
            });

            //保存裁图
            $cropWrapper.on('click', '#save-crop-btn', function () {
                const croppered = $cropDom.cropper('getCroppedCanvas');
                if (croppered.msToBlob) {
                    var blob = croppered.msToBlob();
                    uploadBlob(blob, function(url) {
                        $('.crop-wrapper').remove();
                        $('.crop-wrapper-back').remove();
                        if(url) {
                            options.callback && options.callback(url);
                        } else {
                            options.callback && options.callback();
                        }
                    });
                } else {
                    croppered.toBlob(function (blob) {
                        uploadBlob(blob, function(url) {
                            $('.crop-wrapper').remove();
                            $('.crop-wrapper-back').remove();
                            if(url) {
                                options.callback && options.callback(url);
                            } else {
                                options.callback && options.callback();
                            }
                        });
                    });
                }
            });
        }

        // 获取本地图片,暂时使用一个临时的代理服务器
        function getLocalImage(image) {
            // return '/index.php?r=proxy%2Findex&url=' + encodeURIComponent(image);
            // return 'http://120.25.62.254:2618/proxy/' + encodeURIComponent(image);
            // return image;
            return '/www/proxy/index?url=' + encodeURIComponent(image);
        }

        // 初始化剪裁
        function initCropperDom() {
            var _initCropper = function ($ele) {
                $ele.cropper('destroy').cropper(cropOptions);
                return $ele;
            };

            var $cropperImg = $('#crop-img');
            $cropperImg.attr('src', getLocalImage(options.url));
            _initCropper($cropperImg);
            $cropDom = $cropperImg;
            setTimeout(function () {
                $('.crop-wrapper').show();
            },500);
        }

        checkDom();
        initCropperDom();
        bindEvent();
    }
})(jQuery);