import ripTrack from '../dm-rip-track';
import { cookie, url, ajax } from 'dm-utils-ts';
import { dmEventDelegate, dmDomIndex } from "dm-dom";

window.ajax = ajax;

var statistics = {
  //接口配置数据
  config: {
    //统计class 前缀
    siteClassPrefixs: {
      US: "biz",
      CA: "ca",
      AU: "au",
      UK: "uk",
      FR: "fr",
      DE: "de"
    },
    //set_config  的时候拼接class字段国家前缀
    statistics_types: {
      read: { class: ".hot_deal", name: "read" },
      view: { class: ".hot_deal", name: "deal_view" },
      click: { class: ".hot_deal", name: "deal_click" },
      share: { class: ".hot_deal", name: "deal_share" },
      fav: { class: ".hot_deal", name: "deal_fav" },
      comment: { class: ".hot_deal", name: "deal_comment" },
      ad: { class: ".hot_deal", name: "local_ad_click" },
      search: { class: ".search_analysis", name: "search_event" },
      noInterActionSearch: { class: '.search', name: 'search' }, // 搜索页面, 没点击埋点
      searchClick: { class: '.event', name: 'click' }, // 搜索页面，搜索结果点击埋点
      postSp: { class: ".hot_deal", name: "deal_click" },
      spClick: { class: ".sp", name: "click" },
      spEventView: { class: ".sp", name: "subject_view" },
      spView: { class: ".sp", name: "view" },
      metric: { class: ".metric", name: "view" },
      spZoneSearch: { class: ".search_analysis", name: "sp_search_event" },
      oCClick: { class: ".ugc", name: "ugc_click"}
    },
    //pc 页面配置配置规则 controller-action
    pcFormpage: {
      home: {
        hot: "editor_rec_list",
        rank: "sidebar_list",
        deal: "tab_list",
        ad: "tab_list",
        default: "home"
      },
      category: {
        hot: "editor_rec_list_obj",
        deal: "category_list",
        rank: "top_list_obj",
        ad: "tab_list",
        default: "category_list"
      },
      season: {
        deal: "season_list",
        rank: "top_list_obj",
        default: "season_list"
      },
      search: {
        deal: "search_list",
        ad: "tab_list",
        rank: "top_list_obj",
        default: "search_list"
      },
      rank: { rank: "hot_list", default: "hot_list" },
      deal: {
        hot: "editor_rec_list_obj",
        deal: "deal_detail",
        rank: "top_list_obj",
        default: "deal_detail"
      },
      baoliao: {
        dealHome: "disclosure_home",
        dealDetail: "disclosure_detail",
        deal: "disclosure_detail",
        default: "disclosure"
      },
      default: { deal: "default_list", rank: "top_list_obj" }
    },
    //wap 页面配置
    wapFormpage: {
      home: { deal: "hot_list", default: "home" },
      category: { deal: "category_list", default: "category_list" },
      search: { deal: "search_list", default: "deal_detail" },
      deal: { deal: "deal_detail", default: "deal_detail" },
      baoliao: {
        dealHome: "disclosure_home",
        dealDetail: "disclosure_detail",
        default: "disclosure"
      },
      default: {
        default: "default_list",
        deal: "category_list",
        rank: "top_list_obj"
      }
    },
    //点击位置
    fromobj: {
      button: "title_buy_button",
      img: "content_img_link",
      text: "content_text_link",
      buy_content: "content_buy_link",
      title_img: "title_img",
      title_link: "title_link",
      ad: "ad",
      banner: "banner",
      txt_img_button: "txt_img_button",
      deal_content: "deal_content",
      buy_button: "buy_button",
      list: "list",
      // 这些搜索会使用
      box: "box",
      history: "history",
      store: "store",
      associate: "associate",
      button_enter: "button_enter",
      recommendKeywords: "recommendKeywords" //推荐词统计
    }
  },

  // 以下参数在页面加载的时候 在event_statistics_init 初始化，作为提交数据时使用
  configParm: {
    isSendBeacon: true,
    countryCode: "US",
    siteClassPrefix: "biz",
    posturl: "//api2.apps.dealmoon.com/log/cfe538a7",
    controller: "",
    isTest: false
  },

  ajaxIsReturn: false,

  sleeptime: 1,

  //接口提交的参数
  interfacedata: {
    command: "log/addlog",
    className: "",
    category_value: '',
    name: "",
    userId: 0,
    dealId: "",
    platform: "",
    country: "",
    fromPage: "",
    fromObj: "",
    singleId: "",
    id: "",
    xy: "",
    lan: "",
    data: "",
    source: "", // 外站 utm_source
    version: "1.0"
  },

  event_statistics_init: function (countryCode, controller, interfaceParm) {
    countryCode = countryCode.toUpperCase();
    //初始化参数
    this.event_set_config_parm(countryCode, controller);
    //初始化palctform 和lan
    this.event_set_interface_data(interfaceParm);
    //绑定一般事件
    this.event_statistics_bind();
    //绑定特殊事件（编辑器内容）
    this.event_statistics_content();
  },

  //初始化 posturl siteClassPrefix 绑定事件
  event_set_config_parm: function (countryCode, controller) {
    var config = this.config;
    countryCode = config.siteClassPrefixs.hasOwnProperty(countryCode)
      ? countryCode
      : this.configParm.countryCode;
    this.configParm = {
      countryCode: countryCode,
      siteClassPrefix: config.siteClassPrefixs[countryCode],
      posturl: this.getPostUrl(false),
      controller: controller,
      isTest: this.configParm.isTest,
      isSendBeacon: this.configParm.isSendBeacon
    };
  },

  // 检查统计次数是否超限
  _check_stat_times: function (ele) {
    var data = ele.dataset;
    var times = (data["statrk-times"] || 0) * 1 + 1;
    var limit = (data["statrk-limit"] || Infinity) * 1;

    // 判断点击次数是否达到限制
    if (times > limit) {
      return false;
    }

    ele.setAttribute("statrk-times", times);

    return true;
  },

  // 一般绑定统计函数
  event_statistics_bind: function () {
    dmEventDelegate(document, 'click', ".event_statics_action", function () {
      if (!statistics._check_stat_times(this)) {
        return;
      }
      var parm = this.getAttribute("statrk");
      console.log(parm);
      parm && statistics.action_statics(parm, "", "");
    });
  },

  // 编辑器内容绑定: 只对折扣有用，文章、晒货无用
  event_statistics_content: function () {
    dmEventDelegate(document, 'click', ".event_statistics a", function () {
      if (this.href && this.href.indexOf("exe") != -1) {
        var dealId = this
          .closest(".event_statistics")
          .getAttribute("data-id");
        var parm = "click-" + dealId + "-deal-text--";
        statistics.action_statics(parm, "", "");
      }
    });
  },

  /**
   * parm    html event属性格式 statrk="type-dealId-fromPage-fromObj-singleId-pagetype-category_value-position"
   * controller
   * interfacedata 后置数据，可对parm数据进行重置，interfacedataParm 请传递需更改的对象数据
   * type 事件类型 详见配置 statistics.config.statistics_types 可依据此类型确定class 和 name 建议扩展在此配置中
   * dealId deal id 或 某类型id 直接传入 接口参数dealId 中
   * fromPage 来源页面 详见配置statistics.config.pcFormpage|wapFormpage 建议新增的页面放入此配置中，当传入参数不在此配置中是直接 将参数赋值给interfacedata.fromPage
   * singleId 单品id 直接传入 interfacedata.singleId
   * pagetype 替换interfacedata.type
   * */
  action_statics: function (parm, controller, interfacedataParm) {
    interfacedataParm = interfacedataParm || {};
    //分割参数
    var arraydata = parm.split("-");
    //获取统计类型
    var event_type = arraydata[0];
    //获取来源平台
    var platform = this.interfacedata.platform;
    //判断当前数据类型是否存在
    var isHasEventType = this.config.statistics_types.hasOwnProperty(
      event_type
    );
    //获取当前类型的 calss 和 name 
    var statisticsTypeConfig = isHasEventType
      ? this.config.statistics_types[event_type]
      : {
        class: ".hot_deal",
        name: "deal_view"
      };
    var command =
      statisticsTypeConfig.class == ".metric" ? "log/metric" : "log/addlog";
    //获取当前已设置接口数据
    var interfacedata = this.interfacedata;
    //如果controller 存在则优先使用参数controller ，如果不存在在使用全局this.configParm.controller
    controller = controller || this.configParm.controller;
    //fromPage 逻辑处理
    var fromPage = "";
    var pcFormpage = this.config.pcFormpage;
    var wapFormpage = this.config.wapFormpage;
    //如果配置中有此值 则读取配置中的值 如果没有 则使用参数中的值
    if (platform.toUpperCase() == "PC") {
      //如果配置中没有此controller 则使用默认default
      controller = pcFormpage.hasOwnProperty(controller)
        ? controller
        : "default";
      fromPage = pcFormpage[controller].hasOwnProperty(arraydata[2])
        ? pcFormpage[controller][arraydata[2]]
        : arraydata[2];
      interfacedata.userId = topbar.user.getUserId();
    } else if (platform.toUpperCase() == "WAP") {
      //如果配置中没有此controller 则使用默认default
      controller = wapFormpage.hasOwnProperty(controller)
        ? controller
        : "default";
      fromPage = wapFormpage[controller].hasOwnProperty(arraydata[2])
        ? wapFormpage[controller][arraydata[2]]
        : arraydata[2];
      interfacedata.userId = user.getUserId();
    } else {
      fromPage = arraydata[2];
    }

    // rip埋点细化
    var rip = interfacedataParm.rip || "";
    var rip_value = interfacedataParm.rip_value || "";
    var rip_position = interfacedataParm.rip_position || "";
    var ripDetail = { rip, rip_value, rip_position };
    var ripArr = [];

    if (!rip.length && url.getQuery('rip').length) {
      ripArr = helper.base64decode(url.getQuery('rip')).split('|');

      ripDetail = { rip: decodeURIComponent(ripArr[0]), rip_value: decodeURIComponent(ripArr[1]), rip_position: ripArr[2] };
    }

    if (statistics.interfacedata.xy) {
      statistics.interfacedata.data = { ...statistics.interfacedata.data, xy: statistics.interfacedata.xy }
    }

    statistics.interfacedata = {
      command: command || "log/addlog",
      name: isHasEventType ? statisticsTypeConfig.name : interfacedata.name,
      className: isHasEventType
        ? this.configParm.siteClassPrefix + statisticsTypeConfig.class
        : interfacedata.className, //拼接前缀
      userId: interfacedata.userId,
      dealId: arraydata[1] || "",
      platform: platform,
      fromPage: fromPage,
      fromObj: statistics.config.fromobj[arraydata[3]] || arraydata[3],
      singleId: arraydata[4],
      id: "",
      position: arraydata[6],
      lan: statistics.interfacedata.lan,
      data: statistics.interfacedata.data,
      source: statistics.interfacedata.source,
      type: statistics.interfacedata.type || arraydata[5],
      ripDetail,
      version: "1.0"
    };

    if (arraydata[6]) {
      statistics.interfacedata['category_value'] = arraydata[6];
    }

    if (interfacedataParm) {
      this.event_set_interface_data(interfacedataParm);
    }

    if (!rip.length && ripArr[3] && !/"pvp"/.test(ripArr[3])) {
      try {
        statistics.interfacedata['data'] = { ...statistics.interfacedata['data'], ...JSON.parse(ripArr[3].replace(/'/g, '"')) };
      } catch (e) {
        console.log(e);
      }
    }

    /"pvp"/.test(ripArr[3]) && statistics.add_params_from_previous_page(ripArr[3]);

    // 长文章、笔记详情页折扣排行榜点击后折扣详情页底部购买按钮点击埋点fromObj参数替换回buy_button
    if (/_detail_chart/.test(statistics.interfacedata.ripDetail.rip) && (statistics.config.fromobj[arraydata[3]] === 'buy_button' || arraydata[3] === 'buy_button')) {
      statistics.interfacedata.fromObj = 'buy_button';
    }

    if (statistics.configParm.isTest) {
      console.log("interfacedata", statistics.interfacedata);
    }
    console.log(statistics.interfacedata);
    this.event_statistics_post();
  },

  /**
   * 设置URL中rip参数带过来的来自上一个页面的参数
   * @param {*} ripParams 
   */
  add_params_from_previous_page(ripParams) {
    try {
      const trackRipData = JSON.parse(ripParams);

      const addInterfaceKeys = [{
        name: 'fo',
        key: 'fromObj',
      }, 'position'];

      const addDataKeys = ['keyword', 'isFirstClick', 'index']

      addInterfaceKeys.forEach(item => {
        if (trackRipData['pvp'][typeof (item) === 'string' ? item : item.name] !== undefined) {
          statistics.interfacedata = {
            ...statistics.interfacedata,
            [typeof (item) === 'string' ? item : item.key]: decodeURIComponent(trackRipData['pvp'][typeof (item) === 'string' ? item : item.name]),
          }
        }
      });

      addDataKeys.forEach(key => {
        if (trackRipData['pvp'][key] !== undefined) {
          statistics.interfacedata.data = {
            ...statistics.interfacedata.data,
            [key]: decodeURIComponent(trackRipData['pvp'][key]),
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  },

  // 设置接口data
  event_set_interface_data: function (parm) {
    for (var key in parm) {
      if (this.interfacedata.hasOwnProperty(key) && key !== 'pvp') {
        this.interfacedata[key] = parm[key];
      }
    }
  },

  // 获取埋点提交地址
  getPostUrl: function (isV2 = false) {
    if (/[.\/]+((it|st|at)\w*.)dealmoon.net/.test(location.href)) {
      const siteKey = location.host.match(/(it|st|at)\d+/)[0];

      statistics.configParm.isTest = true;

      return "//analytics." + siteKey + ".dealmoon.net/log/cfe538a7" + (isV2 ? '-v2' : '');
    } else {
      return "//analytics.dealmoon.com/log/cfe538a7" + (isV2 ? '-v2' : '');
    }
  },

  // 埋点提交
  event_statistics_post: function () {
    if (statistics.configParm.isSendBeacon && navigator.sendBeacon) {
      statistics.beaconPost();
    } else {
      // ajax发送埋点请求
      statistics.event_post_ajax({
        command: statistics.interfacedata.command,
        className: statistics.interfacedata.className,
        name: statistics.interfacedata.name,
        userId: statistics.interfacedata.userId || "",
        dealId: statistics.interfacedata.dealId || "",
        category_value: statistics.interfacedata.category_value,
        platform: statistics.interfacedata.platform,
        fromPage: statistics.interfacedata.fromPage,
        fromObj: statistics.interfacedata.fromObj,
        position: statistics.interfacedata.position,
        itemId: statistics.interfacedata.singleId || "",
        lang: statistics.interfacedata.lan,
        id: statistics.interfacedata.id || "",
        type: statistics.interfacedata.type || "",
        data: statistics.interfacedata.data
          ? JSON.stringify(statistics.interfacedata.data)
          : "{}",
        source: statistics.interfacedata.source || "",
        ...(statistics.interfacedata.ripDetail || {})
      });
    }

    if (statistics.ajaxIsReturn == false) {
      statistics.event_sleep();
    }
  },

  // 埋点提交ajax
  event_post_ajax: data => {
    // JSONP
    ajax.getScript(
      statistics.getPostUrl(),
      {
        ...data,
        udid: statistics.udid,
      },
      'console.log'
    ).then(() => {
      statistics.ajaxIsReturn = true;
    }).then(res => {
      statistics.ajaxIsReturn = true;
      throw new Error(res.msg);
    })
  },

  // sendBeacon
  beaconPost: function (data) {
    if (statistics.configParm.isSendBeacon && navigator.sendBeacon) {
      // sendBeacon发送埋点请求, 避免页面跳转取消请求发送
      // 组装php-v2参数
      data = data ? data : {
        command: statistics.interfacedata.command,
        commandInfo: {
          className: statistics.interfacedata.className,
          name: statistics.interfacedata.name,
          value: {
            id: statistics.interfacedata.id || "",
            lang: statistics.interfacedata.lan,
            type: statistics.interfacedata.type || "",
            fromPage: statistics.interfacedata.fromPage,
            fromObj: statistics.interfacedata.fromObj,
            platform: statistics.interfacedata.platform,
            category_value: statistics.interfacedata.category_value,
            data: statistics.interfacedata.data
              ? statistics.interfacedata.data
              : {},
            ...(statistics.interfacedata.ripDetail || {})
          }
        },
      }

      if (statistics.interfacedata.dealId) {
        data['commandInfo']['value']['dealId'] = statistics.interfacedata.dealId;
      }
      if (window.topbar && window.topbar.user && window.topbar.user.getUserId()) {
        data['commandInfo']['value']['userId'] = window.topbar.user.getUserId();
      }

      data['commandInfo'] = JSON.stringify(data['commandInfo']);

      const formData = new FormData();

      Object.keys(data).forEach(key => {
        let value = data[key];
        if (typeof value !== 'string') {
          // formData只能append string 或 Blob
          value = JSON.stringify(value);
        }
        formData.append(key, value);
      });

      navigator.sendBeacon(statistics.getPostUrl(true) + (statistics.udid ? '?udid=' + statistics.udid : ''), formData);
      statistics.ajaxIsReturn = true;
    }
  },

  event_sleep: () => {
    var now = Date.now();
    var exitTime = now + statistics.sleeptime;
    while (true) {
      now = Date.now();
      if (now > exitTime) return;
    }
  },

  /**
   * 设置追踪数据，主要为跨页面后继续追踪准备数据
   * @param data 数据
   * @param trackId 追踪数据的标示字符串，如果有，将设置到LS，并再后续获取(getTrackData)的时候会校验
   */
  setTrackData: (data, trackId) => {
    if (trackId) {
      try {
        // 将追踪数据放到 ls，这样下次详情页面初始化的时候会读取并追踪，问详情页的时候可以继续追踪 keyword 和 index
        localStorage.setItem("_dmtrk_track_id", trackId);
        localStorage.setItem("_dmtrk_data", JSON.stringify(data));
      } catch (e) {
        console.log(e);
      }
    }
  },

  /**
   * 获取追踪数据，主要获取其他页面的追踪数据
   * @param trackId 追踪数据的标示字符串，校验正确后，才会设置到 data
   * @param needRemove 是否需要清掉追踪数据
   */
  getTrackData: function (trackId, needRemove) {
    try {
      if (trackId == localStorage.getItem("_dmtrk_track_id")) {
        var data = JSON.parse(localStorage.getItem("_dmtrk_data"));
        if (needRemove) {
          localStorage.removeItem("_dmtrk_data");
          localStorage.removeItem("_dmtrk_track_id");
        }
        return data;
      }
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * 获取页面访问来源并设置到 interfacedata
   * 通过 document.referrer 判断是否是外站
   * 如果是本站来源，返回空串
   * 如果是外站来源，URL一般会带有utm_source参数，取utm_source作为来源
   */
  setSource: function () {
    const rip = url.getQuery('rip');

    if (rip.length && new RegExp(location.host.replace(/^\w+\./, '[m|www].')).test(document.referrer)) {
      const ripArr = helper.base64decode(rip).split('|')

      if (ripArr.length === 4 && ripArr[3].length) {
        try {
          const param = JSON.parse(ripArr[3])

          if (param.pvp && param.pvp.page) {
            return { from: decodeURIComponent(param.pvp.page) };
          }
          return "";
        } catch (e) {
          throw new Error(e)
        }
      }

      return "";
    }

    return (statistics.interfacedata.source =
      (window.location.href.match(/[\?\&]utm_source=([^\&]+)/i) || [])[1] ||
      "other");
  },

  // 以下函数不再扩展维护， 请使用以上函数处理
  action: "",
  actionparm: "",
  //配置移至 config 参数中
  statistics_type: (function () {
    // 根据国家 countryCode 配置 class 前缀
    var siteClassPrefix = {
      US: "biz.",
      CA: "ca.",
      AU: "au."
    }[window.countryCode || cookie.readCookie("CC") || "US"];
    return {
      event_view: { class: siteClassPrefix + "hot_deal", name: "deal_view" },
      event_click: { class: siteClassPrefix + "hot_deal", name: "deal_click" },
      event_share: { class: siteClassPrefix + "hot_deal", name: "deal_share" },
      event_fav: { class: siteClassPrefix + "hot_deal", name: "deal_fav" },
      event_comment: {
        class: siteClassPrefix + "hot_deal",
        name: "deal_comment"
      }
    };
  })(),
  // 配置移至 config 参数中
  homepagefrom: {
    top: "lock_list",
    exclusive: "exclusive_list",
    orther: "tab_list"
  },

  // 设置从App过来的埋点数据
  event_statistics_app_trk_data: function (data) {
    // 需要埋点的controllers 
    let trkData = url.getQuery('trk-data');
    let { className, name, fromPage } = data;
    if (!trkData) return;
    try {
      statistics.interfacedata.className = `${this.configParm.siteClassPrefix}.${className}`;
      statistics.interfacedata.name = name;
      trkData = JSON.parse(decodeURIComponent(trkData));
      statistics.interfacedata.type = trkData.type;

      if (fromPage) {
        statistics.action_statics(`--${fromPage}`, "", trkData);
      } else {
        statistics.action_statics("", "", trkData);
      }

    } catch (e) { }
  },

  event_set_config: function (event_type, dealId, ident, position, singleId) {
    statistics.interfacedata.fromObj = statistics.interfacedata.fromObj || "";
    statistics.interfacedata.singleId = statistics.interfacedata.singleId || "";
    statistics.interfacedata.dealId = dealId;
    statistics.interfacedata.singleId = singleId ? singleId : "";
    statistics.interfacedata.name = statistics.statistics_type[event_type]
      ? statistics.statistics_type[event_type]["name"]
      : statistics.interfacedata.name;
    statistics.interfacedata.className = statistics.statistics_type[event_type]
      ? statistics.statistics_type[event_type]["class"]
      : statistics.interfacedata.className;
    statistics.interfacedata.userId =
      statistics.interfacedata.platform == "PC"
        ? topbar.user.getUserId()
        : window.user
          ? window.user.getUserId()
          : "";
    statistics.ajaxIsReturn = false;

    if (statistics.interfacedata.platform == "PC") {
      if (statistics.controller == "home") {
        //首页
        if (ident == "deal_list") {
          // 首页（table）
          statistics.interfacedata.fromPage = statistics.homepagefrom[
            statistics.actionparm
          ]
            ? statistics.homepagefrom[statistics.actionparm]
            : statistics.homepagefrom["orther"];
          if (event_type == "event_click") {
            //点击事件
            statistics.interfacedata.fromObj = statistics.config.fromobj[position];
          }
        } else if (ident == "hot") {
          //热门编辑
          statistics.interfacedata.fromPage = "editor_rec_list";
        } else if (ident == "rank") {
          //点击排行
          statistics.interfacedata.fromPage = "sidebar_list";
        } else if (ident == "local_ad") {
          // 首页 Table 中的 local ad 点击
          // 其中 name=local_ad_click
          statistics.interfacedata.name = "local_ad_click";
          statistics.interfacedata.fromPage = "tab_list";
          statistics.interfacedata.fromObj = statistics.config.fromobj[position];
        }
      } else if (statistics.controller == "category") {
        //分类页
        //不记录攻略与众测
        if (statistics.actionparm == "Buying-Guide") {
          return false;
        }
        if (ident == "deal_list") {
          if (event_type == "event_click") {
            statistics.interfacedata.fromPage = "category_list";
            statistics.interfacedata.fromObj = statistics.config.fromobj[position];
          } else {
            // view 收藏  分享事件
            if (statistics.actionparm == "Buying-Guide") {
              //攻略众测
              statistics.interfacedata.fromPage = "guide_list";
            } else {
              // 分类
              statistics.interfacedata.fromPage = "category_list";
            }
          }
        } else if (ident == "rank") {
          if (event_type == "event_view") {
            statistics.interfacedata.fromPage = "category_list";
            statistics.interfacedata.fromObj = "top_list_obj";
          }
        }
      } else if (statistics.controller == "season") {
        //临时season
        statistics.interfacedata.fromPage = "season_list";
        if (ident == "deal_list") {
          if (event_type == "event_click") {
            statistics.interfacedata.fromObj = statistics.config.fromobj[position];
          }
        } else if (ident == "rank") {
          if (event_type == "event_view") {
            statistics.interfacedata.fromObj = "top_list_obj";
          }
        }
      } else if (statistics.controller == "search") {
        //搜索
        statistics.interfacedata.fromPage = "search_list";
        if (ident == "deal_list") {
          if (event_type == "event_click") {
            statistics.interfacedata.fromObj = statistics.config.fromobj[position];
          }
        } else if (ident == "rank") {
          if (event_type == "event_view") {
            statistics.interfacedata.fromObj = "top_list_obj";
          }
        }
      } else if (statistics.controller == "rank") {
        //点击排行
        if (ident == "rank") {
          statistics.interfacedata.fromPage = "hot_list";
        }
      } else if (statistics.controller == "deal") {
        //详情
        statistics.interfacedata.fromPage = "deal_detail";
        if (ident == "deal") {
          if (event_type == "event_click") {
            statistics.interfacedata.fromObj = statistics.config.fromobj[position];
          }
        } else if (ident == "hot") {
          statistics.interfacedata.fromObj = "editor_rec_list_obj";
        } else if (ident == "rank") {
          statistics.interfacedata.fromObj = "related_list_obj";
        }
      } else {
        statistics.interfacedata.fromPage =
          statistics.interfacedata.fromPage || "default_list";
      }
    } else {
      //WAP 端
      if (event_type == "event_view") {
        if (ident != "fav_list") {
          if (statistics.controller == "home") {
            statistics.interfacedata.fromPage = "deal_home";
            statistics.interfacedata.fromObj =
              ["ad", "banner"].includes(position)
                ? statistics.config.fromobj[position]
                : "";
          } else if (statistics.controller == "hot") {
            statistics.interfacedata.fromPage = "hot_list";
            statistics.interfacedata.fromObj = position
              ? statistics.config.fromobj[position]
              : "";
            statistics.interfacedata.fromObj =
              ["ad", "banner"].includes(position)
                ? statistics.config.fromobj[position]
                : "";
          } else if (statistics.controller == "search") {
            statistics.interfacedata.fromPage = "search_list";
          } else if (statistics.controller == "category") {
            statistics.interfacedata.fromPage = "category_list";
          } else {
            //详情
            statistics.interfacedata.fromPage =
              statistics.interfacedata.fromPage || "deal_list";
          }
          if (ident == "local_ad") {
            // 首页 Table 中的 local ad 点击
            // 其中 name=local_ad_click
            statistics.interfacedata.name = "local_ad_click";
            statistics.interfacedata.fromPage = "deal_list";
            statistics.interfacedata.fromObj = statistics.config.fromobj[position];
          }
        } else {
          statistics.interfacedata.fromPage =
            statistics.interfacedata.fromPage || "fav_list";
        }
      } else if (event_type == "event_click") {
        statistics.interfacedata.fromPage =
          statistics.interfacedata.fromPage || "deal_detail";
        statistics.interfacedata.fromObj =
          statistics.interfacedata.fromObj || statistics.config.fromobj[position];
      } else if (event_type == "event_comment") {
        statistics.interfacedata.fromPage =
          statistics.interfacedata.fromPage || "deal_detail";
      }
    }
    return true;
  }
};

window.statistics = statistics;
export default statistics;
