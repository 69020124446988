"use strict";

/**
 * Lazy fn
 * Created by zhengguo.chen on 16/6/23.
 */

(function ($, win, undef) {

    // 限流方法,一段时间内只执行最后一次或超过limitCnt次后执行一次
    $.makeLazy = function (fn, lazyTime, limitCnt) {
        var timer = 0;
        var cnt = 0;
        var createTimer = function createTimer(ctx, args) {
            return setTimeout(function () {
                fn.apply(ctx, args);
            }, lazyTime);
        };
        return function () {
            if (limitCnt && ++cnt >= limitCnt) {
                cnt = 0;
                createTimer(this, Array.prototype.slice.call(arguments));
            }
            clearTimeout(timer);
            timer = createTimer(this, Array.prototype.slice.call(arguments));
        };
    };
})(jQuery, window);