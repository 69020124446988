/**
 * 文件选择与上传 (自动弹出选择框,并上传文件,返回url)
 * author liming
 * 使用方法
 *
 *  $.selectUpload({
                 uploadUrl: ,    //图片上传的url (必填)
                 acceptFiletype:  //文件过滤(非必填)
                 isMultiple: //是否多选 (非必填)
                 //上传后的回调(重要)
                 uploadCallback: function(file) {
                     console.log('uploadCallback', file);
                 },

                //文件选择后的回调,可以用来做上传进度条
                selectCallback: function (files) {
                    console.log('selectCallback', files);
                },
                //更新上传进度
                updataPercentCallback: function(file) {
                    console.log('updataPercentCallback', file);
                }
            });
 */

(function ($) {

    var gOptions;

    function initOptions(options) {
        gOptions = options;
    }

    //弹出上传文件框
    function selectFile(callback) {
        var inputObj = window.document.createElement('input');
        inputObj.setAttribute('type', 'file');
        inputObj.setAttribute("style", 'visibility:hidden');
        if (gOptions.isMultiple) {
            inputObj.setAttribute('multiple', 'multiple');
        }
        if (gOptions.acceptFiletype) {
            inputObj.setAttribute('accept', gOptions.acceptFiletype);
        }
        $(inputObj).on('click', function(e) {
            e.stopPropagation();
        });
        window.document.body.appendChild(inputObj);

        inputObj.onchange = function() {
            var files = Array.prototype.slice.call(inputObj.files);
            if(gOptions.maxSize) {
                files = files.filter(function (file) {
                    return file.size < gOptions.maxSize
                });
            }
            callback && callback(files);
        }

        inputObj.click(); //在ie下这是一个阻塞函数调用，故inputObj.onchange应该写在此调用前。（另外$(inputObj).trigger('click')在ie下效果和此一样）
    }

    //上传单个文件
    function uploadFile(file) {
        var formData = new FormData();
        formData.append("state", "local");
        formData.append('file', file, "blob.png");
        var ajaxPromise = $.ajax({
            url: gOptions.uploadUrl,
            type: 'POST',
            dataType: 'json',
            data: formData,
            contentType: false, //必须false才会自动加上正确的Content-Type
            processData: false, //必须false才会避开jQuery对 formdata 的默认处理
            xhr: function() {
                var xhr = $.ajaxSettings.xhr();
                document.__uploadPercent = '0%';
                xhr.upload.onprogress = function(e) {
                    var uploadPercent = (e.loaded / e.total * 100);
                    if(gOptions.updataPercentCallback
                        && typeof gOptions.updataPercentCallback == 'function') {
                        gOptions.updataPercentCallback({
                                fileId: file.fileId,
                                percent: uploadPercent
                            });
                    }
                };
                xhr.upload.onload = function() {
                    if(gOptions.updataPercentCallback
                        && typeof gOptions.updataPercentCallback == 'function') {
                        gOptions.updataPercentCallback({
                            fileId: file.fileId,
                            percent: 100
                        });
                    }
                };
                xhr.upload.onabort = function() {
                };
                return xhr;
            }
        });

        ajaxPromise.then(function(result) {
            result.fileId = file.fileId;
            gOptions.uploadCallback && gOptions.uploadCallback(result);
        }, function(err) {
            console.log('uploadFile ajaxPromise err:', err);
            gOptions.uploadCallback && gOptions.uploadCallback();
        });
    }

    function setFileId(files) {
        files.forEach(function (file, index) {
            file.fileId = 'file_' + new Date().getTime() + index;
        });
    }

    $.selectUpload = function(options) {
        initOptions(options);
        selectFile(function (files) {
            //给每个文件生成一个唯一ID
            setFileId(files);

            //选择文件回调
            if(files && options.selectCallback && typeof options.selectCallback == 'function') {
                options.selectCallback(files, function ( start ) {
                    //开始上传
                    if(files.length > 0 && start ) {
                        files.forEach(function (file) {
                            uploadFile(file);
                        })
                    }
                });
            } else {
                if(files.length > 0 ) {
                    files.forEach(function (file) {
                        uploadFile(file);
                    })
                }
            }
        });
    }

})(jQuery);