/*
 * @Author: Gang Jiang 
 * @Date: 2021-12-15 11:44:42 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2021-12-15 14:14:14
 */
import contains from "./contains";
import eventDelegate from './delegate';

export const domContains = contains;
export const dmEventDelegate = eventDelegate;