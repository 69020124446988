(function ($, win, undef) {
    /**
     * 回到顶部
     * Created by zhengguo.chen on 16/6/23.
     */
    $.fn.goToTop = function (opt) {
        var $this = $(this);

        opt = $.extend({
            minScrollHeight: $(window).height(),
            animate: false,
            container: win
        }, opt || {});

        var $container = $(opt.container);

        var isScrolling = false;

        var checkTimer = 0;
        var clearCnt = 0;
        var createScrollCheckTimer = function() {
            if(++clearCnt < 10) {
                clearTimeout(checkTimer);
            } else {
                clearCnt = 0;
            }
            checkTimer = setTimeout(function() {
                var scrollTop = $container.scrollTop();
                !isScrolling && $this.toggleClass('show', scrollTop >= opt.minScrollHeight);
            }, 100);
        }

        $container.on('scroll', createScrollCheckTimer).trigger('scroll');

        $this.on('click', function(e) {
            $this.removeClass('show');
            isScrolling = true;
            var $scrollContainer = opt.container === win ? $("body,html") : $container;
            $scrollContainer.animate({
                scrollTop: 0
            }, opt.animate ? 500 : 0, function () {
                isScrolling = false;
            });
            e.stopPropagation();
        });
    };
})(jQuery, window);